import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Typography,
  styled,
} from "@mui/material";
import { blueGrey } from "@mui/material/colors";
import AddImage from "../../assets/img/Add.png";
import React, { useState } from "react";
import ModalOutputTypeCreate from "./modals/ModalOutputTypeCreate";

const IMAGE_HEIGHT = 200;

const Card = styled(MuiCard)(({ theme }) => ({
  "& .updatedModelImageWrapper": {
    height: IMAGE_HEIGHT,
    overflow: "hidden",
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    display: "flex",
  },
  "& .plusImage": {
    alignItems: "center",
    height: IMAGE_HEIGHT,
    left: "50%",
  },
  "& .cardContent": {
    height: 60,
    overflow: "hidden",
  },
  "& .avatar": {
    backgroundColor: blueGrey[800],
    textSizeAdjust: "small",
  },
  "& .header": {
    height: 75,
  },
}));

function AddNewOutputWidget() {
  const [
    showCreateSummaryOutputTypeModal,
    setShowCreateSummaryOutputTypeModal,
  ] = useState(false);

  const handleOutputTypeCreateModalToggle = () =>
    setShowCreateSummaryOutputTypeModal(!showCreateSummaryOutputTypeModal);

  return (
    <Card sx={{ height: "100%" }}>
      <CardHeader
        className="header"
        avatar={null} // NOTE: Feel free to chuck this back in, just hide it on xs, maybe sm as well
        title="Suggest a new output type"
        subheader={`Creates a new draft summary Asset Output to share`}
        subheaderTypographyProps={{ noWrap: true, variant: "caption" }}
        titleTypographyProps={{ noWrap: true, variant: "subtitle1" }}
      />
      <div className="updatedModelImageWrapper">
        <img
          src={AddImage}
          alt="AddImage"
          className="plusImage"
          onClick={handleOutputTypeCreateModalToggle}
        />
      </div>
      <CardContent className="cardContent">
        <Typography variant="body2" color="textSecondary" component="p">
          {"Start a new page here"}
        </Typography>
      </CardContent>
      <ModalOutputTypeCreate
        open={showCreateSummaryOutputTypeModal}
        onCancelCallback={handleOutputTypeCreateModalToggle}
        onCompleteCallback={handleOutputTypeCreateModalToggle}
      />
    </Card>
  );
}

export default AddNewOutputWidget;
