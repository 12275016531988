import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import ListIcon from "@mui/icons-material/ListAlt";
import { IOutputTypeInputFieldListValue } from "../../../utilities/types/OutputTypeInputFieldListValue";
import FormOutputTypeInputFieldListValueCreate from "../forms/FormOutputTypeInputFieldListValueCreate";

export interface IModalOutputTypeInputFieldListValueCreateProps {
  open: boolean;
  outputTypeInputFieldId: string;
  onCancelCallback(): void;
  onCompleteCallback(file?: IOutputTypeInputFieldListValue): void;
}

function ModalOutputTypeInputFieldListValueCreate({
  onCancelCallback,
  open,
  onCompleteCallback,
  outputTypeInputFieldId,
}: IModalOutputTypeInputFieldListValueCreateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Add a new list value"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<ListIcon />}
    >
      <DialogContent>
        <FormOutputTypeInputFieldListValueCreate
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          outputTypeInputFieldId={outputTypeInputFieldId}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalOutputTypeInputFieldListValueCreate;
