import { IOutputTypeInputFieldListValue } from "../../utilities/types/OutputTypeInputFieldListValue";


export const ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE = {
    INVALIDATE: 'INVALIDATE_SUMMARY_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUES',
    RECEIVE: 'RECEIVE_SUMMARY_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUES',
    DELETE: 'DELETE_SUMMARY_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE',
    UPDATE: 'UPDATE_SUMMARY_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUES',
}

interface ActionSummaryOutputTypeInputFieldListValuesInvalidate {
    type: typeof ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE.INVALIDATE,
    byId: { [key: string]: IOutputTypeInputFieldListValue },
}

interface ActionSummaryOutputTypeInputFieldListValuesReceive {
    type: typeof ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD_LIST_VALUE.RECEIVE
    byId: { [key: string]: IOutputTypeInputFieldListValue },
}

export interface SummaryOutputTypeInputFieldListValueState {
    byId: { [key: string]: IOutputTypeInputFieldListValue },
}

export enum SummaryOutputTypeInputFieldListValueSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesSummaryOutputTypeInputFieldListValue = ActionSummaryOutputTypeInputFieldListValuesReceive | ActionSummaryOutputTypeInputFieldListValuesInvalidate;