import { AppThunk } from "..";
import { GetSolverJobResponseDemo, USE_DEV_DEFAULTS } from "../../components/solverJobs/WizardStateDefaults";
import { AddQueryStringsToUrl, CheckStatus, GetDefaultHeaders, ShowExceptionAsMessage } from "../../utilities/ApiUtils";
import { Configuration } from "../../utilities/Constants";
import { PrepareBody, ShowError } from "../../utilities/Helpers";
import { IApiResponse } from "../../utilities/types/Api";
import { ISolverJob, SolverJobStatusEnum, TSolverInputFieldValue } from "../../utilities/types/SolverJob";
import { ACTIONS_SOLVER_JOB, SolverJobSearchOrderTypeEnum } from "./types";

interface IPrerequisites {
  assetJobIds?: string[];
  outputJobIds?: string[];
  solverJobIds?: string[];
}
export interface IFetchCreateSolverJobProps {
  modelId: string;
  solverId: string;
  solverInputFieldValueMap: { [key: string]: TSolverInputFieldValue };
  jobName: string;
  inputBucket: string | null;
  outputBucket: string | null;
  inputPrefix: string | null;
  outputPrefix: string | null;
  prerequisites?: IPrerequisites;
  terminateOnErrorCount: number | null;
}

export const receiveSolverJobs = (solverJobs: ISolverJob[]) => {
  var byIdObjectToDispatch: { [key: string]: ISolverJob } = {};

  for (var i = 0; i < solverJobs.length; i++) {
    byIdObjectToDispatch[solverJobs[i].solverJobId] = solverJobs[i];
  }

  return {
    type: ACTIONS_SOLVER_JOB.RECEIVE,
    byId: byIdObjectToDispatch,
  };
};

export const requestDeleteSolverJob = (solverJob: ISolverJob) => ({
  type: ACTIONS_SOLVER_JOB.DELETE,
  byId: { [solverJob.solverJobId]: solverJob },
});

export interface IFetchUpdateSolverJobProps {
  solverJobId: string;
  name: string;
  description: string;
  orderNumber: number;
}

export const fetchUpdateSolverJob =
  (props: IFetchUpdateSolverJobProps): AppThunk<Promise<ISolverJob>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverJobs`, {
        method: "PUT",
        headers: headers,
        body: PrepareBody(props),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverJobs) {
        dispatch(receiveSolverJobs(parsedResp.data.solverJobs));
        return parsedResp.data.solverJobs;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error updating solver job.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error updating solver job.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface IFetchSearchSolverJobsProps {
  pageNumber: number;
  pageSize: number;
  modelId?: string;
  solverJobId?: string;
  solverId?: string;
  assetJobId?: string;
  text?: string;
  createdBy?: string;
  orderType?: SolverJobSearchOrderTypeEnum;
  status?: SolverJobStatusEnum;
}

export const fetchSearchSolverJobs =
  (searchParams: IFetchSearchSolverJobsProps): AppThunk<Promise<ISolverJob[]>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, false, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverJobs`, searchParams), {
        method: "GET",
        headers: headers,
      });

      // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverJobs) {
        dispatch(receiveSolverJobs(parsedResp.data.solverJobs));
        return parsedResp.data.solverJobs;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error searching solver job.");
          return [];
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error searching solver job.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return [];
    }
  };

export interface IFetchDeleteSolverJobProps {
  solverJobId: string;
}

export const fetchDeleteSolverJob =
  (props: IFetchDeleteSolverJobProps): AppThunk<Promise<ISolverJob>> =>
  async (dispatch) => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverJobs`, props), {
        method: "DELETE",
        headers: headers,
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (
        parsedResp &&
        parsedResp.success &&
        parsedResp.data &&
        parsedResp.data.solverJobs &&
        parsedResp.data.solverJobs.length
      ) {
        dispatch(requestDeleteSolverJob(parsedResp.data.solverJobs[0]));
        return parsedResp.data.solverJobs[0];
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error deleting solver job.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error deleting solver job.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export const fetchCreateSolverJob =
  (solverJobToCreate: IFetchCreateSolverJobProps): AppThunk<Promise<ISolverJob>> =>
  async (dispatch) => {
    if (USE_DEV_DEFAULTS) {
      const solverJob = GetSolverJobResponseDemo(solverJobToCreate, `demo-${Math.floor(Math.random() * 100000)}`);
      dispatch(receiveSolverJobs([solverJob]));
      return solverJob;
    }

    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/SolverJobs`, {
        method: "POST",
        headers: headers,
        body: PrepareBody(solverJobToCreate),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverJob) {
        dispatch(receiveSolverJobs([parsedResp.data.solverJob]));
        return parsedResp.data.solverJob;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error creating solver job.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error creating solver job.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };

export interface ICancelSolverJobProps {
  solverJobId: string;
}

export const cancelSolverJob =
  (cancelSolverJob: ICancelSolverJobProps): AppThunk<Promise<any>> =>
  async () => {
    var headers = await GetDefaultHeaders(true, true);

    try {
      var apiResponse = await fetch(`${Configuration.BASE_API_URL}/SolverJobs/cancel`, {
        method: "POST",
        headers: headers,
        body: PrepareBody(cancelSolverJob),
      });

      var parsedResp: IApiResponse = await CheckStatus(apiResponse);
      if (parsedResp && parsedResp.success) {
        return parsedResp.data;
      } else {
        if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
          ShowError("Error cancelling solver job.");
          return null;
        }
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        ShowExceptionAsMessage(e);
        console.log("Error cancelling solver job.", e.stack);
      } else {
        // Handle other types of exceptions or unknown errors.
        console.error("Unknown error:", e);
      }
      return;
    }
  };
