import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import LayoutDefault from "../components/layout/LayoutDefault";
import PageAdminDashboard from "../components/admin/pages/PageAdminDashboard";
import PageAdminImports from "../components/admin/pages/PageAdminImports";
import PageUserManagement from "../components/userDetails/pages/PageUserManagement";
import PageFeedbackManagement from "../components/feedback/pages/PageFeedbackManagement";
import PageIdentifiersManagement from "../components/identifier/pages/PageIdentifiersManagement";
import PageSolverManagement from '../components/solver/pages/PageSolverManagement';
import PageDiscussionManagement from '../components/discussion/pages/PageDiscussionManagement';
import PageUserRoleManagement from '../components/userRoles/pages/PageUserRoleManagement';
import PageAssetOutputManagement from "../components/outputType/pages/PageAssetOutputManagement";

const RouteAdmin = () => {
  let match = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${match.path}/imports`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageAdminImports {...props} />
          </LayoutDefault>
        )}
      />
      <Route
        path={`${match.path}/users`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageUserManagement routeProps={props} />
          </LayoutDefault>
        )}
      />
      <Route
        path={`${match.path}/feedback`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageFeedbackManagement routeProps={props} />
          </LayoutDefault>
        )}
      />
      <Route
        path={`${match.path}/identifiers`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageIdentifiersManagement routeProps={props} />
          </LayoutDefault>
        )}
      />
      <Route
        path={`${match.path}/solvers`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageSolverManagement routeProps={props} />
          </LayoutDefault>
        )}
      />
      <Route
        path={`${match.path}/outputs`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageAssetOutputManagement routeProps={props} />
          </LayoutDefault>
        )}
      />
      <Route
        path={`${match.path}/discussions`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageDiscussionManagement routeProps={props} />
          </LayoutDefault>
        )}
      />
      <Route
        path={`${match.path}/userRoles`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageUserRoleManagement routeProps={props} />
          </LayoutDefault>
        )}
      />
      <Route
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageAdminDashboard {...props} />
          </LayoutDefault>
        )}
      />
    </Switch>
  );
};

export default RouteAdmin;
