import React from "react";
import { Grid, Breadcrumbs } from "@mui/material";

import { Container } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import SolverJobsEnquiryContainer from "../SolverJobsEnquiryContainer";
import JobsStatusChart from "../JobsStatusChart";
import { useSelector } from "react-redux";
import { selectorGetSolverJobs } from "../../../redux/solverJob/selectors";
import { RootState } from "../../../redux";
import Link from "@mui/material/Link";
import { HomeIconLink } from "../../generic/HomeIconLink";
import { useFetchSolversPageHook } from "../../solver/Hooks";

const PageJobsEnquiry = (routeProps: RouteComponentProps<any>) => {
  useFetchSolversPageHook({
    pageNumber: 1,
    pageSize: 100,
    minPageNumberToFetch: 1,
  });
  const solverJobs = useSelector((store: RootState) => selectorGetSolverJobs(store));

  return (
    <Container maxWidth="xl" style={{ paddingTop: 16 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <HomeIconLink />
        <Link color="textPrimary" aria-current="page" underline="none">
          Solver Jobs
        </Link>
      </Breadcrumbs>
      <Grid container spacing={5} style={{ marginTop: 16 }}>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          <JobsStatusChart solverJobs={solverJobs} loading={false} />
        </Grid>

        <Grid item xs={12} sm={6} md={7} lg={8}>
          <SolverJobsEnquiryContainer canEdit={true} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageJobsEnquiry;
