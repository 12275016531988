import { IOutputType } from "../../utilities/types/OutputType";


export const ACTIONS_OUTPUT_TYPE = {
    INVALIDATE: 'INVALIDATE_SUMMARY_OUTPUT_TYPES',
    RECEIVE: 'RECEIVE_SUMMARY_OUTPUT_TYPES',
    DELETE: 'DELETE_SUMMARY_OUTPUT_TYPE',
    UPDATE: 'UPDATE_SUMMARY_OUTPUT_TYPES',
}

interface ActionOutputTypesInvalidate {
    type: typeof ACTIONS_OUTPUT_TYPE.INVALIDATE,
    byId: { [key: string]: IOutputType },
}

interface ActionOutputTypesReceive {
    type: typeof ACTIONS_OUTPUT_TYPE.RECEIVE
    byId: { [key: string]: IOutputType },
}

export interface OutputTypeState {
    byId: { [key: string]: IOutputType },
}

export enum OutputTypeSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
}

export type ActionTypesOutputType = ActionOutputTypesReceive | ActionOutputTypesInvalidate;