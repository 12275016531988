import { IOutputJob } from "../../utilities/types/OutputJob";

export const ACTIONS_SUMMARY_OUTPUT_JOB = {
  INVALIDATE: "INVALIDATE_SUMMARY_OUTPUT_JOB",
  RECEIVE: "RECEIVE_SUMMARY_OUTPUT_JOB",
  DELETE: "DELETE_SUMMARY_OUTPUT_JOB",
  UPDATE: "UPDATE_SUMMARY_OUTPUT_JOB",
};

interface ActionSummaryOutputJobInvalidate {
  type: typeof ACTIONS_SUMMARY_OUTPUT_JOB.INVALIDATE;
  byId: { [key: string]: IOutputJob };
}

interface ActionSummaryOutputJobReceive {
  type: typeof ACTIONS_SUMMARY_OUTPUT_JOB.RECEIVE;
  byId: { [key: string]: IOutputJob };
}

export interface SummaryOutputJobState {
  byId: { [key: string]: IOutputJob };
}

export enum OutputJobSearchOrderTypeEnum {
  CreatedDateDesc = 10,
  CreatedDateAsc = 20,
}

export type ActionTypesSummaryOutputJob = ActionSummaryOutputJobReceive | ActionSummaryOutputJobInvalidate;
