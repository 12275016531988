import { ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD, SummaryOutputTypeInputFieldSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IOutputTypeInputField, OutputTypeInputFieldTypeEnum } from '../../utilities/types/OutputTypeInputField';


export const receiveSummaryOutputTypeInputFields = (summaryOutputTypeInputFields: IOutputTypeInputField[]) => {

    var byIdObjectToDispatch: { [key: string]: IOutputTypeInputField } = {};

    for (var i = 0; i < summaryOutputTypeInputFields.length; i++) {
        byIdObjectToDispatch[summaryOutputTypeInputFields[i].summaryOutputTypeInputFieldId] = summaryOutputTypeInputFields[i];
    }

    return {
        type: ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteSummaryOutputTypeInputField = (summaryOutputTypeInputField: IOutputTypeInputField) => ({
    type: ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD.DELETE,
    byId: { [summaryOutputTypeInputField.summaryOutputTypeInputFieldId]: summaryOutputTypeInputField }
});


export interface IFetchCreateOutputTypeInputFieldProps {
    outputTypeId: string,
    label: string,
    description?: string,
    defaultValue: number,
    minValue?: number,
    maxValue?: number,
    orderNumber: number,
    type: OutputTypeInputFieldTypeEnum,
}


export const fetchCreateOutputTypeInputField = (outputTypeInputFieldToCreate: IFetchCreateOutputTypeInputFieldProps): AppThunk<Promise<IOutputTypeInputField>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/summaryOutputTypeInputFields`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(outputTypeInputFieldToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.summaryOutputTypeInputField) {
            dispatch(receiveSummaryOutputTypeInputFields([parsedResp.data.summaryOutputTypeInputField]));
            return parsedResp.data.summaryOutputTypeInputField;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating input field.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateSummaryOutputTypeInputFieldProps {
    outputTypeInputFieldId: string,
    label: string,
    description?: string,
    defaultValue: number,
    defaultString?: string,
    defaultDateTime?: Date,
    minValue?: number,
    maxValue?: number,
    decimalPlaces?: number,
    orderNumber: number,
    type: OutputTypeInputFieldTypeEnum,
}


export const fetchUpdateOutputTypeInputField = (props: IFetchUpdateSummaryOutputTypeInputFieldProps): AppThunk<Promise<IOutputTypeInputField>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/summaryOutputTypeInputFields`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.summaryOutputTypeInputField) {
            dispatch(receiveSummaryOutputTypeInputFields([parsedResp.data.summaryOutputTypeInputField]));
            return parsedResp.data.summaryOutputTypeInputField;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating input field.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchOutputTypeInputFieldsProps {
    pageNumber: number,
    pageSize: number,
    summaryOutputTypeId?: string,
    text?: string,
    createdBy?: string,
    orderType?: SummaryOutputTypeInputFieldSearchOrderTypeEnum,
}


export const fetchSearchOutputTypeInputFields = (searchParams: IFetchSearchOutputTypeInputFieldsProps): AppThunk<Promise<IOutputTypeInputField[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/summaryOutputTypeInputFields`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.summaryOutputTypeInputFields) {
            dispatch(receiveSummaryOutputTypeInputFields(parsedResp.data.summaryOutputTypeInputFields));
            return parsedResp.data.summaryOutputTypeInputFields;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching input field.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteSummaryOutputTypeInputFieldProps {
    summaryOutputTypeInputFieldId: string,
}


export const fetchDeleteSummaryOutputTypeInputField = (props: IFetchDeleteSummaryOutputTypeInputFieldProps): AppThunk<Promise<IOutputTypeInputField>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/summaryOutputTypeInputFields`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.summaryOutputTypeInputField) {
            dispatch(requestDeleteSummaryOutputTypeInputField(parsedResp.data.summaryOutputTypeInputField));
            return parsedResp.data.summaryOutputTypeInputField;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting input field.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting input field.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}