import React from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import { useDispatch } from "react-redux";
import InputIcon from "@mui/icons-material/Input";
import FormOutputTypeInputFieldUpdate from "../forms/FormOutputTypeInputFieldUpdate";
import { IOutputTypeInputField } from "../../../utilities/types/OutputTypeInputField";

export interface IModalOutputTypeInputFieldUpdateProps {
  open: boolean;
  outputTypeInputField: IOutputTypeInputField;
  onCancelCallback(): void;
  onCompleteCallback(outputTypeInputField?: IOutputTypeInputField): void;
  canEdit: boolean;
}

function ModalOutputTypeInputFieldUpdate({
  onCancelCallback,
  open,
  canEdit,
  onCompleteCallback,
  outputTypeInputField,
}: IModalOutputTypeInputFieldUpdateProps) {
  const dispatch = useDispatch();

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Update a field"}
      subtitle={"Fill in the form to complete."}
      headerIcon={<InputIcon />}
    >
      <DialogContent style={{ maxHeight: "600px" }}>
        <FormOutputTypeInputFieldUpdate
          canEdit={canEdit}
          dispatch={dispatch}
          onCancelCallback={onCancelCallback}
          onCompleteCallback={onCompleteCallback}
          outputTypeInputField={outputTypeInputField}
        />
      </DialogContent>
    </WidgetModalBase>
  );
}

export default ModalOutputTypeInputFieldUpdate;
