import React, { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import HeaderIcon from "@mui/icons-material/BubbleChartTwoTone";
import { DialogActions, Button, Divider } from "@mui/material";
import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { useFetchOutputTypeMappingsPageHook } from "../Hooks";
import { IOutputType } from "../../../utilities/types/OutputType";
import { fetchCreateOutputTypeMapping } from "../../../redux/solverOutputTypeMapping/actions";
import { useDispatch, useSelector } from "react-redux";
import SolverOutputTypePickList from "../SolverOutputTypePickList";
import { selectorGetOutputTypeMappingsBySolverId } from "../../../redux/solverOutputTypeMapping/selectors";
import { selectorGetOutputTypes } from "../../../redux/outputType/selectors";
import { RootState } from "../../../redux";
import { useFetchOutputTypesPageHook } from "../../outputType/Hooks";

export interface IModalOutputTypeMappingsProps {
  open: boolean;
  onCancelCallback(): void;
  solverId: string;
}

function ModalSolverOutputTypeMappings({
  onCancelCallback,
  open,
  solverId,
}: IModalOutputTypeMappingsProps) {
  const dispatch = useDispatch();
  const [selectedSolverOutputTypes, setSelectedOutputTypes] = useState<IOutputType[]>([]);
  const [saving, setSaving] = useState(false);
  const { fetching: fetchingValues } = useFetchOutputTypeMappingsPageHook({
    minPageNumberToFetch: 0,
    pageNumber: 1,
    pageSize: 500,
    solverId: solverId,
  });

  const { fetching: fetchingOValues } = useFetchOutputTypesPageHook({
    minPageNumberToFetch: 0,
    pageNumber: 1,
    pageSize: 500,
  });

  const outputTypes = useSelector((store: RootState) => selectorGetOutputTypes(store));
  const outputTypeMappings = useSelector((store: RootState) =>
    selectorGetOutputTypeMappingsBySolverId(store, solverId)
  );

  async function onSaveClick() {
    setSaving(true);

    var resp = await dispatch(
      fetchCreateOutputTypeMapping({
        solverId,
        summaryOutputTypeIds: selectedSolverOutputTypes.map((x) => x.summaryOutputTypeId),
      })
    );

    setSaving(false);
    if (resp !== null) onCancelCallback();
  }

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Manage solver output type mappings"}
      subtitle={"Configure output types that can be used with this solver."}
      headerIcon={<HeaderIcon />}
      style={{ minWidth: 475 }}
    >
      <DialogContent style={{ marginBottom: 16 }}>
        <SolverOutputTypePickList
          outputTypes={outputTypes}
          outputTypeMappings={outputTypeMappings}
          solverId={solverId}
          onSelectedItemsChange={setSelectedOutputTypes}
        />
        <LoaderAbsoluteCentred loading={fetchingValues || fetchingOValues || saving} />
      </DialogContent>
      <Divider light={true} />
      <DialogActions style={{ marginTop: 8 }}>
        <Button
          
          variant="outlined"
          // style={{ display: 'flex', flexGrow: 1 }}
          onClick={onCancelCallback}
          fullWidth={true}
        >
          Close
        </Button>
        <Button
          color="primary"
          variant="contained"
          // style={{ display: 'flex', flexGrow: 1 }}
          onClick={onSaveClick}
          fullWidth={true}
        >
          Submit
        </Button>
      </DialogActions>
    </WidgetModalBase>
  );
}

export default ModalSolverOutputTypeMappings;
