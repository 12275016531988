import { ActionTypesSolverOutputTypeMapping, SolverOutputTypeMappingState, ACTIONS_SOLVER_OUTPUT_TYPE_MAPPING } from "./types";

const initialState: SolverOutputTypeMappingState = {
    byId: {},
}


export function OutputTypeMappingsReducer(
    state = initialState,
    action: ActionTypesSolverOutputTypeMapping
): SolverOutputTypeMappingState {
    switch(action.type){
        case ACTIONS_SOLVER_OUTPUT_TYPE_MAPPING.INVALIDATE: {
            return {
                ...initialState,
            }
        }
        case ACTIONS_SOLVER_OUTPUT_TYPE_MAPPING.DELETE:

            let currentById = Object.assign({}, state.byId);
            delete currentById[Object.keys(action.byId)[0]];

            return {
                ...state,
                byId: currentById,
            }
        case ACTIONS_SOLVER_OUTPUT_TYPE_MAPPING.RECEIVE: {
            return {
                ...state,
                byId: { ...state.byId, ...action.byId },
            }
        }
        default:
            return state;
    }
}