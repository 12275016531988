import { Box, Button, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchOutputTypeMappingsBySolverIdIfNeeded } from "../../redux/solverOutputTypeMapping/actions";
import { fetchOutputTypesIfNeeded } from "../../redux/outputType/actions";
import { selectorGetOutputTypesBySolverId } from "../../redux/outputType/selectors";
import { GetSolverJobLinkBySolverJobId, openInNewTab } from "../../routes/RouteLinkHelpers";
import { ISolver } from "../../utilities/types/Solver";
import { ISolverJob } from "../../utilities/types/SolverJob";
import { ISolverJobTask } from "../../utilities/types/SolverJobTask";
import { IOutputType, TOutputTypeInputFieldValue } from "../../utilities/types/OutputType";
import { IOutputTypeInputField } from "../../utilities/types/OutputTypeInputField";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { useFetchSolversPageHook } from "../solver/Hooks";
import { useFetchSolverInputFieldsPageHook } from "../solverInputField/Hooks";
import { useFetchSolverInputFieldListValuesPageHook } from "../solverInputFieldListValue/Hooks";
import SolverOutputTypePickList from "../solverOutputMappingType/SolverOutputTypePickList";
import OutputTypeInputFieldTabPanel from "../outputTypeInputField/OutputTypeInputFieldTabPanel";
import {
  applyFieldChangeToValueMap,
  getReportInputsFromValueMap,
  setupFieldValueMap,
  useItemIsLoading,
} from "../outputTypeInputField/valueMapHelper";
import { TabStyleWrapper } from "./JobCreateSolverJobStep";
import useJobCreateState from "./WizardState";
import { fetchCreateOutputJob } from "../../redux/outputJob/actions";

interface IJobSolverSubmitStepProps {
  onCompleteCallback(): void;
}

function JobSolverReportsStep({ onCompleteCallback }: IJobSolverSubmitStepProps) {
  const { fetching: fetchingSolvers } = useFetchSolversPageHook({
    minPageNumberToFetch: 1,
    pageNumber: 1,
    pageSize: 100,
  });
  const { fetching: fetchingSolverFields } = useFetchSolverInputFieldsPageHook({
    minPageNumberToFetch: 1,
    pageNumber: 1,
    pageSize: 100,
  });
  const { fetching: fetchingSolverFieldListValues } = useFetchSolverInputFieldListValuesPageHook({
    minPageNumberToFetch: 1,
    pageNumber: 1,
    pageSize: 100,
  });

  const [solverBundles, loadingBundles] = useSolverBundlesSelector();

  return (
    <JobSolverSubmitStepDisplay
      solverBundles={solverBundles}
      onCompleteCallback={onCompleteCallback}
      loading={fetchingSolvers || fetchingSolverFields || fetchingSolverFieldListValues || loadingBundles}
    />
  );
}

/** Groups these tightly integrated entities so we can display then in the tabs and performs actions easily.
 * The solver job task is supplied when the wizard will *not* generate summary reports (i.e. 'fileId' is blank).
 */
type ISolverBundle = { solver: ISolver; solverJob: ISolverJob; solverJobTask: ISolverJobTask | undefined };

/** Group solvers, solver jobs, and solver job tasks for easy usage later.
 * @returns A list of bundles and whether it's loading.
 */
function useSolverBundlesSelector(): [ISolverBundle[], boolean] {
  const solvers = useSelector((store: RootState) => store.solvers.byId);
  const solverJobs = useJobCreateState((s) => s.selectedSolverJobs);
  //const [tasksByJobId, loadingTasks] = usePingForSolverJobTasks(solverJobs);

  // Group entities into the bundle
  const getSolverBundles: () => ISolverBundle[] = () =>
    solverJobs
      .map(
        (solverJob) =>
          ({
            solverJob,
            solver: solvers[solverJob.solverId],
            //solverJobTask: tasksByJobId[solverJob.solverJobId],
          } as ISolverBundle)
      )
      .filter((sb) => !!sb.solver);

  // Cache the bundle index operation so we don't run it on every update
  //const solvers = Object.keys(solvers).length;
  //const solverJobs = solverJobs.length;
  //const tasksByJobId = Object.keys(tasksByJobId).length;

  const solverBundles = useMemo(getSolverBundles, [solverJobs, solvers]);

  // Fetch output types for the solvers we're using (we get IDs via the bundles so we don't fetch all solvers)
  const solverIds = solverBundles.map((sp) => sp.solver.solverId);
  const loadingOutputTypes = useFetchOutputTypes(solverIds);

  const loading = loadingOutputTypes;
  return [solverBundles, loading];
}

/** Fetch output types and update the global store for the given solvers. */
function useFetchOutputTypes(solverIds: string[]) {
  const dispatch = useDispatch();
  const useSummaryReports = true; // was useJobCreateSummaryReports();

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    if (useSummaryReports) {
      dispatch(fetchOutputTypesIfNeeded());
      for (const solverId of solverIds) dispatch(fetchOutputTypeMappingsBySolverIdIfNeeded(solverId));
    }
     
    setLoading(false);
    // eslint-disable-next-line
  }, [dispatch, solverIds.length, useSummaryReports]);
  return loading;
}

interface IJobSolverSubmitStepDisplayProps {
  solverBundles: ISolverBundle[];
  loading: boolean;
  onCompleteCallback(): void;
}

function JobSolverSubmitStepDisplay({ solverBundles, loading, onCompleteCallback }: IJobSolverSubmitStepDisplayProps) {
  const [activeSolver, setActiveSolver] = React.useState(0);
  const handleTabChange = (e: ChangeEvent<{}>, activeSolver: number) => setActiveSolver(activeSolver);

  const markedTabs = useJobCreateState((s) => s.markedTabsReportsStep);

  return (
    <TabStyleWrapper>
      <Tabs
        id="solver-tabs"
        orientation="vertical"
        variant="scrollable"
        value={activeSolver}
        onChange={handleTabChange}
        className="tabs"
      >
        {solverBundles.map(({ solverJob, solver }) => {
          const tabIsMarked = markedTabs.has(solverJob.solverJobId);
          return (
            <Tab
              key={`${solver.solverId}-${solverJob.solverJobId}`}
              className={`${tabIsMarked ? "solverJobTabCompleted" : "tabWrapper"}`}
              label={<Typography variant="button">{solverJob.name ?? solver.name}</Typography>}
              style={{
                overflowX: "hidden",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            />
          );
        })}
      </Tabs>
      {solverBundles.map((bundle, i) => {
        const { solver, solverJob } = bundle;
        return (
          <SolverDisplayTab value={activeSolver} index={i} key={solver.solverId}>
            <Grid container spacing={3} id="customisation-panel">
              {markedTabs.has(solverJob.solverJobId) ? (
                <GenerateCompletePanel solverBundle={bundle} onCompleteCallback={onCompleteCallback} />
              ) : (
                <OutputJobSelector {...bundle} />
              )}
            </Grid>
          </SolverDisplayTab>
        );
      })}
      <LoaderAbsoluteCentred loading={loading} />
    </TabStyleWrapper>
  );
}

interface ISolverDisplayTabProps {
  children: React.ReactNode;
  value: number;
  index: number;
  solverJob?: ISolverJob;
}

function SolverDisplayTab({ children, value, index }: ISolverDisplayTabProps) {
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className="tabPanelWrapper"
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function OutputJobSelector({ solver, solverJob, solverJobTask }: ISolverBundle) {
  const solverId = solver.solverId,
    solverJobId = solverJob.solverJobId;


  const dispatch = useDispatch();
  const [loadingPage, setLoadingPage] = useState(false);
  const [outputTypeIsLoading, setOutputTypeIsLoading] = useItemIsLoading();

 

  const [selectedOutputTypes, setSelectedOutputTypes] = useState<IOutputType[]>([]);
  const solverSummaryOutputTypes = useSelector((store: RootState) =>
    selectorGetOutputTypesBySolverId(store, solverId)
  );

  const useSummaryReports = true; // was useJobCreateSummaryReports();
  //const canGenerate =
  //  !(loadingPage || outputTypeIsLoading) && useSummaryReports
  //    ? !!selectedSummaryOutputTypes.length
  //    : solverJobTaskId && !!selectedOutputTypes.length; // Ensure the task was fetched before we submit

  async function generateReports() {
    const state = useJobCreateState.getState();

    setLoadingPage(true);

    if (useSummaryReports) {
      await dispatch(
        fetchCreateOutputJob({
          solverJobId,
          summaryOutputTypeInputs: getReportInputsFromValueMap(
            state.summaryOutputInputValuesObjectMap,
            selectedOutputTypes
          ),
          prerequisites: { assetJobIds: [], solverJobIds: [solverJobId], outputJobIds: [] },
        })
      );
    }
  

    state.markTabReportsStep(solverJobId);

    setLoadingPage(false);
  }

  const viewJobLink = () => openInNewTab(GetSolverJobLinkBySolverJobId(solverJobId));

  // Handle any subsequent field changes and apply them to the base object
  const summaryOutputInputValuesObjectMap = useJobCreateState((s) => s.summaryOutputInputValuesObjectMap);

  const setupSummaryOutputFieldValues = useCallback((fields: IOutputTypeInputField[]) => {
    const valuesMap = useJobCreateState.getState().summaryOutputInputValuesObjectMap;
    const newValuesMap = setupFieldValueMap(valuesMap, fields);
    useJobCreateState.setState({ summaryOutputInputValuesObjectMap: newValuesMap });
  }, []);

  const applySummaryOutputFieldValueChange = useCallback(
    (summaryOutputFieldTypeId: string, fieldId: string, value: TOutputTypeInputFieldValue) => {
      const valuesMap = useJobCreateState.getState().summaryOutputInputValuesObjectMap;
      const newValuesMap = applyFieldChangeToValueMap(valuesMap, summaryOutputFieldTypeId, fieldId, value);
      useJobCreateState.setState({ summaryOutputInputValuesObjectMap: newValuesMap });
    },
    []
  );

  return (
    <>
      <Grid item xs={12}>
        <Typography color="textPrimary">
          Select outputs for {solverJob.name ?? solver.name} (
          {solver.name})
        </Typography>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {
            useSummaryReports && (
              <SolverOutputTypePickList
                solverId={solverId}
                onSelectedItemsChange={setSelectedOutputTypes}
                outputTypes={solverSummaryOutputTypes}
                outputTypeMappings={[]}
                leftLabel="Available"
                rightLabel="Selected"
              />
            )
            //: (
            //  <SolverSingleAssetOutputsPickList
            //    solverId={solverId}
            //    onSelectedItemsChange={setSelectedOutputTypes}
            //    singleAssetOutputs={singleAssetOutputs}
            //    outputTypeMappings={[]}
            //    leftLabel="Available"
            //    rightLabel="Selected"
            //  />
            //)
          }
        </Grid>
      </Grid>

      {!!selectedOutputTypes.length && (
        <Grid item xs={12}>
          {selectedOutputTypes.map((outputType, i) => (
            <OutputTypeInputFieldTabPanel
              outputType={outputType}
              defaultValueOverrides={
                summaryOutputInputValuesObjectMap[outputType.summaryOutputTypeId] || {}
              }
              onValueChangeCallback={applySummaryOutputFieldValueChange}
              onInputFieldsChanged={setupSummaryOutputFieldValues}
              onLoadChange={setOutputTypeIsLoading}
              key={outputType.summaryOutputTypeId}
              showDivider={i !== 0}
            />
          ))}
        </Grid>
      )}

      <Grid item xs={12} style={{ textAlign: "right" }}>
        <Divider style={{ marginBottom: 16 }} />
        <div id="submit-buttons">
          <Button
            onClick={viewJobLink}
            variant="outlined"
            style={{ paddingLeft: 12, paddingRight: 12, marginRight: 8 }}
          >
            View solver job
          </Button>
          <Button onClick={generateReports} variant="contained" color="primary">
            Generate
          </Button>
        </div>
      </Grid>
      <LoaderAbsoluteCentred loading={loadingPage} />
    </>
  );
}

function GenerateCompletePanel({
  solverBundle,
  onCompleteCallback,
}: {
  solverBundle: ISolverBundle;
  onCompleteCallback(): void;
}) {
  const viewJobLink = () => openInNewTab(GetSolverJobLinkBySolverJobId(solverBundle.solverJob.solverJobId));

  return (
    <div style={{ textAlign: "center", maxWidth: 400, marginLeft: "auto", marginRight: "auto" }}>
      <Typography variant="body1">Reports Generating</Typography>
      <Typography variant="body1" color="textSecondary">
        Your reports will generate once all solver jobs are complete. Generate further reports via the tabs on the side
        or finish by clicking below.
      </Typography>
      <div style={{ textAlign: "center", marginTop: 16 }}>
        <Button variant="contained" color="primary" fullWidth onClick={onCompleteCallback} style={{ marginBottom: 16 }}>
          Go to dashboard
        </Button>

        <Button variant="outlined" fullWidth onClick={viewJobLink}>
          View solver job
        </Button>
      </div>
    </div>
  );
}

export default JobSolverReportsStep;
