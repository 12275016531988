import React, { memo, useEffect, useState } from "react";
import { TextField, Grid, Divider, Button, Autocomplete } from "@mui/material";
import { Formik } from "formik";

import LoaderAbsoluteCentred from "../../generic/loaders/LoaderAbsoluteCentred";
import { getFormikFieldProps } from "../../../utilities/Helpers";
import WidgetModalConfirmationDialog from "../../generic/widgets/modals/WidgetModalConfirmationDialog";
import { solverValidationSchema, NODE_TYPES } from "../constants";
import { ISolverDetails, ISolverFormValues } from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { selectorGetSolverById, selectorGetSolvers } from "../../../redux/solver/selectors";
import { SolverTabContentPreSubmit } from "../../solverJobs/JobCreateSolverJobStep";
import { GetUserId } from "../../../utilities/ApiUtils";
import useJobCreateState, { SolverInputValuesObjectMap } from "../../solverJobs/WizardState";
import { selectorGetSolverInputFieldsBySolverId } from "../../../redux/solverInputField/selectors";
import { getSolverDefaultFieldValue } from "../../assetInputField/defaultFieldHelper";

const ShowSolverDetails = memo(
  ({
    solverId,
    isEditMode,
    solverInputFieldValueMap,
  }: {
    solverId: string;
    isEditMode: boolean;
    solverInputFieldValueMap: ISolverDetails["solverInputFieldValueMap"];
  }) => {
    const userId = GetUserId();
    const [show, setShow] = useState<boolean>(false);
    const solver = useSelector((store: RootState) => selectorGetSolverById(store, solverId));

    const solverInputFields = useSelector((store: RootState) =>
      selectorGetSolverInputFieldsBySolverId(store, solverId)
    );

    // Prepare inital values for solver input fields
    useEffect(() => {
      if (solverInputFields.length) {
        var newCurrentValuesObject: SolverInputValuesObjectMap = {};
        for (var solverInputField of solverInputFields) {
          var value = getSolverDefaultFieldValue(solverInputField);
          let valuesObject = newCurrentValuesObject[solverInputField.solverId] || {};
          valuesObject[solverInputField.solverInputFieldId] = value;
          newCurrentValuesObject[solverInputField.solverId] = valuesObject;
        }
        if (
          isEditMode &&
          solverInputFieldValueMap &&
          Object.keys(solverInputFieldValueMap).length > 0 &&
          newCurrentValuesObject[solverId]
        ) {
          newCurrentValuesObject[solverId] = {
            ...newCurrentValuesObject[solverId],
            ...solverInputFieldValueMap,
          };
        }
        useJobCreateState.setState({ solverInputValuesObject: newCurrentValuesObject });
        setShow(true);
      }
    }, [isEditMode, solverInputFieldValueMap, solverInputFields]);

    if (!solver || !show) return null;
    return (
      <SolverTabContentPreSubmit
        isFromCreateModal={false}
        isFromWorkflowSolverNode={true}
        solver={solver}
        userId={userId}
        onSubmit={() => {}}
      />
    );
  }
);

interface FormAddUpdateSolverProps {
  solverDetails: ISolverDetails;
  onCancelCallback(): void;
  onCompleteCallback(newSolverDetails: ISolverFormValues, nodeType: NODE_TYPES, nodeId?: string): void;
  onDelete(nodeId: string, nodeType: NODE_TYPES): void;
}

const FormAddUpdateSolver: React.FC<FormAddUpdateSolverProps> = ({
  solverDetails: {
    isEditMode,
    nodeId = "",
    solverName = "",
    solverId = "",
    solverInputFieldValueMap,
  },
  onCancelCallback,
  onCompleteCallback,
  onDelete,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  const solvers = useSelector((store: RootState) => selectorGetSolvers(store));

  async function deleteHandler() {
    setShowDeleteConfirmation(false);
    onDelete(nodeId, NODE_TYPES.SOLVER);
  }

  const handleSubmit = async (values: ISolverFormValues) => {
    const selectedSolver = solvers.find((solver) => solver.solverId === values.solverId)?.name ?? "";
    const { solverInputValuesObject } = useJobCreateState.getState();

    const reqObj = {
      solverInputFieldValueMap: solverInputValuesObject[values.solverId],
      solverId: values.solverId,
      solverName: selectedSolver,
    };

    if (isEditMode) {
      onCompleteCallback(reqObj, NODE_TYPES.SOLVER, nodeId);
      return;
    }
    onCompleteCallback(reqObj, NODE_TYPES.SOLVER);
  };

  return (
    <Formik
      initialValues={{ solverName, solverId }}
      validationSchema={solverValidationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => {
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  {...getFormikFieldProps(props, "solverObj", "Select Solver")}
                  style={{ display: "block" }}
                  defaultValue={solvers.find((solver) => {
                    return solver.solverId === props.values.solverId;
                  })}
                  options={solvers}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, value) => {
                    props.setFieldValue("solverObj", value);
                    props.setFieldValue("solverId", value ? value.solverId : "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      error={Boolean(props.errors.solverId)}
                      helperText={props.errors.solverId}
                      variant="standard"
                      {...params}
                      label="Select Solver"
                    />
                  )}
                />
              </Grid>
              <ShowSolverDetails
                solverId={props.values.solverId}
                isEditMode={isEditMode}
                solverInputFieldValueMap={solverInputFieldValueMap}
              />
              <Grid item xs={12}>
                <Divider light={true} />
              </Grid>
              <Grid item xs={12} style={{ textAlign: "right" }}>
                {isEditMode && (
                  <Button
                    color="secondary"
                    disabled={props.isSubmitting}
                    style={{ marginRight: 16 }}
                    variant="contained"
                    onClick={toggleDeleteConfirmation}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  disabled={props.isSubmitting}
                  variant="text"
                  style={{ marginRight: 16 }}
                  onClick={onCancelCallback}
                >
                  Close
                </Button>
                <Button disabled={props.isSubmitting} variant="outlined" color="primary" onClick={props.submitForm}>
                  {isEditMode ? "Update" : "Add"}
                </Button>
              </Grid>
              <LoaderAbsoluteCentred loading={props.isSubmitting} />
              <WidgetModalConfirmationDialog
                open={showDeleteConfirmation}
                title="Delete solver"
                subtitle="Confirm solver delete"
                description="Are you sure that you'd like to remove this solver?"
                onCancelCallback={toggleDeleteConfirmation}
                onConfirmCallback={deleteHandler}
                confirmButtonText="Delete"
              />
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

export default FormAddUpdateSolver;
