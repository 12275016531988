import { TextField } from "@mui/material";
import React, { useState } from "react";
import { DEFAULT_DECIMAL_PLACES, useDecimalValue } from "../../utilities/inputFields/numberFieldUtils";
import { TOutputTypeInputFieldValue } from "../../utilities/types/OutputType";
import { IOutputTypeInputField } from "../../utilities/types/OutputTypeInputField";

interface OutputTypeInputFieldDisplayNumberProps {
  outputTypeInputField: IOutputTypeInputField;
  disabled?: boolean;
  onValueChangeCallback(fieldId: string, value: TOutputTypeInputFieldValue): void;
  overriddenDefaultValue?: TOutputTypeInputFieldValue;
}

function OutputTypeInputFieldDisplayNumber({
  outputTypeInputField,
  overriddenDefaultValue,
  onValueChangeCallback,
  disabled = false,
}: OutputTypeInputFieldDisplayNumberProps) {
  const defaultValue = overriddenDefaultValue?.value || outputTypeInputField.defaultValue;
  const decimalPlaces = outputTypeInputField.decimalPlaces || DEFAULT_DECIMAL_PLACES;

  const [error, setError] = useState("");
  const [value, setValue] = useDecimalValue(defaultValue, decimalPlaces);

  function changeValue(e: any) {
    if (isNaN(e.target.value)) {
      setError("A valid number is required.");
      return;
    }

    setValue(e.target.value); // Allow empty values

    var value = Number(e.target.value);
    if (value < outputTypeInputField.minValue || value > outputTypeInputField.maxValue) {
      setError(
        `Must be a value between ${outputTypeInputField.minValue} and ${outputTypeInputField.maxValue}`
      );
      return;
    }

    setError("");
    onValueChangeCallback(outputTypeInputField.summaryOutputTypeInputFieldId, { value });
  }

  return (
    <TextField
      id={`txt-${outputTypeInputField.summaryOutputTypeInputFieldId}`}
      type="number"
      label={outputTypeInputField.label}
      value={value}
      inputProps={{
        min: outputTypeInputField.minValue,
        max: outputTypeInputField.maxValue,
      }}
      onChange={changeValue}
      error={error !== ""}
      helperText={error}
      fullWidth
      variant="outlined"
      {...(disabled === true ? { disabled } : null)}
    />
  );
}

export default OutputTypeInputFieldDisplayNumber;
