import React from "react";
import { TOutputTypeInputFieldValue } from "../../utilities/types/OutputType";
import { IOutputTypeInputField, OutputTypeInputFieldTypeEnum } from "../../utilities/types/OutputTypeInputField";

import OutputTypeInputFieldDisplayNumber from "./OutputTypeInputFieldDisplayNumber";
import OutputTypeInputFieldDisplayString from "./OutputTypeInputFieldDisplayString";
import TypeInputFieldDisplayEnumeration from "./TypeInputFieldDisplayEnumeration";
import OutputTypeInputFieldDisplayDateTime from "./OutputTypeInputFieldDisplayDateTime";


interface IOutputTypeInputFieldDisplayProps {
    outputTypeInputField: IOutputTypeInputField,
    disabled?: boolean,
    onValueChangeCallback(fieldId: string, value: TOutputTypeInputFieldValue): void,
    overriddenDefaultValue?: TOutputTypeInputFieldValue,
}

function OutputTypeInputFieldDisplay(props: IOutputTypeInputFieldDisplayProps) {
    switch (props.outputTypeInputField.type) {
        case OutputTypeInputFieldTypeEnum.Number:
            return <OutputTypeInputFieldDisplayNumber {...props} />;
        case OutputTypeInputFieldTypeEnum.CustomEnumeration:
            return <TypeInputFieldDisplayEnumeration {...props} />;
        case OutputTypeInputFieldTypeEnum.String:
            return <OutputTypeInputFieldDisplayString {...props} />;
        case OutputTypeInputFieldTypeEnum.DateTime:
            return <OutputTypeInputFieldDisplayDateTime {...props} />;
        default:
            return <>Invalid field type</>;
    }
}


export default OutputTypeInputFieldDisplay;