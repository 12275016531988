import { IUserRole } from "../../utilities/types/UserRole";


export const ACTIONS_USER_ROLES = {
    INVALIDATE: 'INVALIDATE_USER_ROLES',
    RECEIVE: 'RECEIVE_USER_ROLES',
    UPDATE: 'UPDATE_USER_ROLES',
    DELETE: 'DELETE_COMPONENT',
}

interface ActionUserRolesInvalidate {
    type: typeof ACTIONS_USER_ROLES.INVALIDATE,
    byId: { [key: string]: IUserRole },
}

interface ActionUserRolesReceive {
    type: typeof ACTIONS_USER_ROLES.RECEIVE
    byId: { [key: string]: IUserRole },
}

interface ActionUserRolesReceive {
    type: typeof ACTIONS_USER_ROLES.UPDATE
    byId: { [key: string]: IUserRole },
}

export interface UserRoleState {
    byId: { [key: string]: IUserRole },
}
export interface IFetchPaginationProps {
    pageNumber: number;
    pageSize: number;
  }
 
  export interface IFetchUserDetailsSearchType extends IFetchPaginationProps {
    text?: string;
  }
  export interface IFetchUserDetailsPaginationType extends IFetchPaginationProps {
    userDetailId?: string;
  }
  export type ActionTypesUserRole = ActionUserRolesReceive | ActionUserRolesReceive | ActionUserRolesInvalidate;