import { AssetInputFieldTypeEnum, IAssetInputField, TAssetInputFieldValue } from "../../utilities/types/AssetInputField";
import { ISolverInputField, SolverInputFieldTypeEnum } from "../../utilities/types/SolverInputField";
import { TSolverInputFieldValue } from "../../utilities/types/SolverJob";
import { TOutputTypeInputFieldValue } from "../../utilities/types/OutputType";
import { IOutputTypeInputField, OutputTypeInputFieldTypeEnum } from "../../utilities/types/OutputTypeInputField";

export function getSolverDefaultFieldValue(inputField: ISolverInputField) {
    var fieldValue: TSolverInputFieldValue = {};
    switch (inputField.type) {
        case SolverInputFieldTypeEnum.Number:
            fieldValue.value = inputField.defaultValue
            break;
        case SolverInputFieldTypeEnum.CustomEnumeration:
            fieldValue.value = inputField.defaultValue
            fieldValue.solverInputFieldValueId = inputField.defaultSolverInputFieldListValueId
            break;
        case SolverInputFieldTypeEnum.DateTime:
            fieldValue.valueDateTime = inputField.defaultDateTime
            break;
        case SolverInputFieldTypeEnum.String:
            fieldValue.valueString = inputField.defaultString
            break;
    }
    return fieldValue
}

export function getAssetDefaultFieldValue(inputField: IAssetInputField) {
    var fieldValue: TAssetInputFieldValue = {};
    switch (inputField.type) {
        case AssetInputFieldTypeEnum.Number:
            fieldValue.value = inputField.defaultValue
            break;
        case AssetInputFieldTypeEnum.CustomEnumeration:
            fieldValue.value = inputField.defaultValue
            fieldValue.assetInputFieldValueId = inputField.defaultAssetInputFieldListValueId
            break;
        case AssetInputFieldTypeEnum.DateTime:
            fieldValue.valueDateTime = inputField.defaultDateTime
            break;
        case AssetInputFieldTypeEnum.String:
            fieldValue.valueString = inputField.defaultString
            break;
    }
    return fieldValue
}

export function getOutputTypeDefaultFieldValue(inputField: IOutputTypeInputField) {
    var fieldValue: TOutputTypeInputFieldValue = {};
    switch (inputField.type) {
        case OutputTypeInputFieldTypeEnum.Number:
            fieldValue.value = inputField.defaultValue
            break;
        case OutputTypeInputFieldTypeEnum.CustomEnumeration:
            fieldValue.value = inputField.defaultValue
            fieldValue.summaryOutputTypeInputFieldValueId = inputField.defaultSummaryOutputTypeInputFieldListValueId
            break;
        case OutputTypeInputFieldTypeEnum.DateTime:
            fieldValue.valueDateTime = inputField.defaultDateTime
            break;
        case OutputTypeInputFieldTypeEnum.String:
            fieldValue.valueString = inputField.defaultString
            break;
    }
    return fieldValue
}