import { ACTIONS_OUTPUT_TYPE, OutputTypeSearchOrderTypeEnum } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { IOutputType, OutputTypeStatus } from '../../utilities/types/OutputType';
import { selectorGetOutputTypes } from './selectors';


export const receiveSummaryOutputTypes = (summaryOutputTypes: IOutputType[]) => {

    var byIdObjectToDispatch: { [key: string]: IOutputType } = {};

    for (var i = 0; i < summaryOutputTypes.length; i++) {
        byIdObjectToDispatch[summaryOutputTypes[i].summaryOutputTypeId] = summaryOutputTypes[i];
    }

    return {
        type: ACTIONS_OUTPUT_TYPE.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteSummaryOutputType = (summaryOutputType: IOutputType) => ({
    type: ACTIONS_OUTPUT_TYPE.DELETE,
    byId: { [summaryOutputType.summaryOutputTypeId]: summaryOutputType }
});


export interface IFetchCreateSummaryOutputTypeProps {
    name: string,
    description: string,
    inputBucket: string,
    outputBucket: string,
    status: OutputTypeStatus,
}


export const fetchCreateSummaryOutputType = (summaryOutputTypeToCreate: IFetchCreateSummaryOutputTypeProps): AppThunk<Promise<IOutputType>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/summaryOutputTypes`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(summaryOutputTypeToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.summaryOutputTypes) {
            dispatch(receiveSummaryOutputTypes(parsedResp.data.summaryOutputTypes));
            return parsedResp.data.summaryOutputTypes;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating  output type.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating output type.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchUpdateSummaryOutputTypeProps {
    summaryOutputTypeId: string,
    name: string,
    description: string,
}


export const fetchUpdateSummaryOutputType = (props: IFetchUpdateSummaryOutputTypeProps): AppThunk<Promise<IOutputType>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/summaryOutputTypes`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.summaryOutputTypes) {
            dispatch(receiveSummaryOutputTypes(parsedResp.data.summaryOutputTypes));
            return parsedResp.data.summaryOutputTypes;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating  output type.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating  output type.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export interface IFetchSearchOutputTypesProps {
    pageNumber: number,
    pageSize: number,
    summaryOutputTypeId?: string,
    text?: string,
    createdBy?: string,
    orderType?: OutputTypeSearchOrderTypeEnum,
}


export const fetchSearchOutputTypes = (searchParams: IFetchSearchOutputTypesProps): AppThunk<Promise<IOutputType[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/summaryOutputTypes`, searchParams), {
            method: 'GET',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.summaryOutputTypes) {

            for (var summaryOutputType of parsedResp.data.summaryOutputTypes) {
                delete summaryOutputType.mainImage;
            }

            dispatch(receiveSummaryOutputTypes(parsedResp.data.summaryOutputTypes));
            return parsedResp.data.summaryOutputTypes;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching output type.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching output type.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteOutputTypeProps {
    summaryOutputTypeId: string,
}


export const fetchDeleteOutputType = (props: IFetchDeleteOutputTypeProps): AppThunk<Promise<IOutputType>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/summaryOutputTypes`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.summaryOutputTypes && parsedResp.data.summaryOutputTypes.length) {
            dispatch(requestDeleteSummaryOutputType(parsedResp.data.summaryOutputTypes[0]));
            return parsedResp.data.summaryOutputTypes[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting output type.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting output type.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export const fetchOutputTypesIfNeeded = (): AppThunk<Promise<void>> => async (dispatch, getState) => {

    if (!selectorGetOutputTypes(getState()).length) {
        await dispatch(fetchSearchOutputTypes({ pageNumber: 1, pageSize: 100 }));
    }

    return;
}


export interface IFetchImportOutputTypeProps {
    fileId: string,
}


export const fetchImportOutputType = (props: IFetchImportOutputTypeProps): AppThunk<Promise<IOutputType>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/summaryOutputTypes/import`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.summaryOutputType) {
            dispatch(receiveSummaryOutputTypes([parsedResp.data.summaryOutputType]));
            return parsedResp.data.summaryOutputType;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error while importing output type.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error retrieving output type.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}