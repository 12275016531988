import { Typography, IconButton, InputLabel, styled } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import React, { useState } from "react";
import { IOutputTypeInputFieldListValue } from "../../utilities/types/OutputTypeInputFieldListValue";
import { selectorGetOutputTypeInputFieldListValuesByOutputTypeInputFieldId } from "../../redux/outputTypeInputFieldListValue/selectors";
import AddIcon from "@mui/icons-material/AddOutlined";
import ModalOutputTypeInputFieldListValueCreate from "./modals/ModalOutputTypeInputFieldListValueCreate";
import ModalOutputTypeInputFieldListValueUpdate from "./modals/ModalOutputTypeInputFieldListValueUpdate";

const DivWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
  "& .fieldValueWrapper": {
    border: "1px solid #EEE",
    padding: "3px 5px",
    borderRadius: "4px",
    margin: "5px 0px",
    transition: "background 0.25s ease-out",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "rgba(0,0,0,0.1)",
    },
  },
}));

interface IOutputTypeInputFieldListValueMaintenanceSectionProps {
  canEdit: boolean;
  outputTypeInputFieldId: string;
}

function OutputTypeInputFieldListValueMaintenanceSection({
  outputTypeInputFieldId,
  canEdit,
}: IOutputTypeInputFieldListValueMaintenanceSectionProps) {
  const outputTypeInputFieldListValues = useSelector((store: RootState) =>
    selectorGetOutputTypeInputFieldListValuesByOutputTypeInputFieldId(
      store,
      outputTypeInputFieldId
    )
  );

  return (
    <OutputTypeInputFieldListValueMaintenanceSectionDisplay
      canEdit={canEdit}
      outputTypeInputFieldId={outputTypeInputFieldId}
      outputTypeInputFieldListValues={outputTypeInputFieldListValues}
    />
  );
}

interface IOutputTypeInputFieldListValueMaintenanceSectionDisplayProps {
  canEdit: boolean;
  outputTypeInputFieldId: string;
  outputTypeInputFieldListValues: IOutputTypeInputFieldListValue[];
}

function OutputTypeInputFieldListValueMaintenanceSectionDisplay({
  outputTypeInputFieldListValues,
  outputTypeInputFieldId,
}: IOutputTypeInputFieldListValueMaintenanceSectionDisplayProps) {
  const [showModalOutputTypeInputFormListValueCreate, setShowModalOutputTypeInputFormListValueCreate] =
    useState(false);
  const [outputTypeInputFieldListValueToEdit, setOutputTypeInputFieldListValueToEdit] =
    useState<IOutputTypeInputFieldListValue>();

  const toggleShowModalOutputTypeInputFormListValueCreate = () =>
    setShowModalOutputTypeInputFormListValueCreate(!showModalOutputTypeInputFormListValueCreate);

  return (
    <DivWrapper>
      <InputLabel shrink={true}>Values</InputLabel>
      <div>
        {outputTypeInputFieldListValues.map((outputTypeInputFieldListValue) => {
          return (
            <div
              className="fieldValueWrapper"
              onClick={() => setOutputTypeInputFieldListValueToEdit(outputTypeInputFieldListValue)}
            >
              <Typography component="p">{outputTypeInputFieldListValue.displayText}</Typography>
              <Typography component="span" variant="caption" style={{ opacity: 0.75 }}>
                Value is {outputTypeInputFieldListValue.value}
                {outputTypeInputFieldListValue.isDefault ? " (default)" : ""}{" "}
              </Typography>
            </div>
          );
        })}
      </div>

      <div>
        <IconButton onClick={toggleShowModalOutputTypeInputFormListValueCreate}>
          <AddIcon />
        </IconButton>
      </div>
      <ModalOutputTypeInputFieldListValueCreate
        open={showModalOutputTypeInputFormListValueCreate}
        outputTypeInputFieldId={outputTypeInputFieldId}
        onCancelCallback={toggleShowModalOutputTypeInputFormListValueCreate}
        onCompleteCallback={toggleShowModalOutputTypeInputFormListValueCreate}
      />
      {outputTypeInputFieldListValueToEdit && (
        <ModalOutputTypeInputFieldListValueUpdate
          onCancelCallback={() => setOutputTypeInputFieldListValueToEdit(undefined)}
          onCompleteCallback={() => setOutputTypeInputFieldListValueToEdit(undefined)}
          open={outputTypeInputFieldListValueToEdit !== undefined}
          outputTypeInputFieldListValue={outputTypeInputFieldListValueToEdit}
        />
      )}
    </DivWrapper>
  );
}

export default OutputTypeInputFieldListValueMaintenanceSection;
