import {
  Button,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";

import HeaderIcon from "@mui/icons-material/BubbleChartTwoTone";
import EditSummaryOutputTypeIcon from "@mui/icons-material/EditOutlined";
import DownloadIcon from "@mui/icons-material/GetAppRounded";
import ManageFieldsIcon from "@mui/icons-material/InputTwoTone";
import ClassDefinitionsIcon from "@mui/icons-material/MapTwoTone";
import MenuIcon from "@mui/icons-material/MenuOutlined";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetFileById } from "../../redux/file/selectors";
import { fetchSearchOutputTypes } from "../../redux/outputType/actions";
import { selectorGetOutputTypes } from "../../redux/outputType/selectors";
import { DownloadObjectAsFile } from "../../utilities/Helpers";
import { FileTypeEnum } from "../../utilities/types/File";
import { IOutputType } from "../../utilities/types/OutputType";
import { useFileSourceSingleHook } from "../file/Hooks";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import ModalOutputTypeInputFields from "../outputTypeInputField/modals/ModalOutputTypeInputFields";
import { useFetchOutputTypesPageHook } from "./Hooks";
import { fetchSearchOutputTypeInputFields } from "../../redux/outputTypeInputField/actions";
import { fetchSearchOutputTypeInputFieldListValues } from "../../redux/outputTypeInputFieldListValue/actions";
import ModalOutputTypeCreate from "./modals/ModalOutputTypeCreate";
import ModalOutputTypeUpdate from "./modals/ModalOutputTypeUpdate";
import ModalOutputTypeDefinitions from "./modals/OutputTypeDefinitions";

const imageDiameter = 50;
const DivWrapper = styled("div")(({ theme }) => ({
  "& .outputTypeWrapper": {
    border: "1px solid #EEE",
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    position: "relative",
  },
  "& .outputTypeImageWrapper": {
    width: imageDiameter,
    maxWidth: "8.3%",
    textAlign: "center",
    display: "inline-block",
    position: "absolute",
    left: 0,
    bottom: 0,
    top: 0,
    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    overflow: "hidden",
    borderRight: "rgba(0,0,0,0.1)",
  },
  "& .OutputTypeImage": {
    objectFit: "cover",
    filter: "sepia(20%)",
    height: "100%",
    width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  "& .outputTypeMainWrapper": {
    marginLeft: theme.spacing(1),
  },
  "& .btnAddOutputType": {
    display: "flex",
    flexGrow: 1,
    marginLeft: "auto",
    marginRight: 0,
    marginBottom: theme.spacing(3),
  },
}));

type IOutputTypeEnquiryContainerProps = {
  canEdit: boolean;
};

const OutputTypeEnquiryContainer = ({ canEdit }: IOutputTypeEnquiryContainerProps) => {
  const dispatch = useDispatch();
  const summaryOutputTypes = useSelector((store: RootState) => selectorGetOutputTypes(store));

  const { fetching } = useFetchOutputTypesPageHook({
    pageNumber: 1,
    pageSize: 1,
    minPageNumberToFetch: 1,
  });

  const onUploadComplete = async (summaryOutputTypeId: string) => {
    await dispatch(fetchSearchOutputTypes({ summaryOutputTypeId, pageNumber: 1, pageSize: 1 }));
  };

  return (
    <OutputTypeEnquiryContainerDisplay
      canEdit={canEdit}
      outputTypes={summaryOutputTypes}
      loading={fetching}
      onUploadComplete={onUploadComplete}
    />
  );
};

type IOutputTypeEnquiryContainerDisplayProps = {
  canEdit: boolean;
  outputTypes: IOutputType[];
  loading: boolean;
  onUploadComplete(summaryOutputTypeId: string): void;
};

const OutputTypeEnquiryContainerDisplay = ({
  outputTypes,
  loading,
  canEdit,
}: IOutputTypeEnquiryContainerDisplayProps) => {
  const [showCreateOutputTypeModal, setShowCreateOutputTypeModal] = useState(false);
  const [outputTypeToUpdate, setOutputTypeToUpdate] = useState<IOutputType>();

  function toggleShowCreateOutputTypeModal() {
    setShowCreateOutputTypeModal(!showCreateOutputTypeModal);
  }

  function onOutputTypeClick(outputType: IOutputType) {
    setOutputTypeToUpdate(outputType);
  }

  function hideOutputTypeUpdate() {
    setOutputTypeToUpdate(undefined);
  }

  return (
    <WidgetSectionBase
      headerText="Fleet Level Outputs"
      subheaderText="Create and maintain fleet level outputs."
      headerIcon={<HeaderIcon />}
      fullWidthHeader={true}
      style={{ height: "100%" }}
    >
      <DivWrapper>
        {canEdit ? (
          <Button
            color="primary"
            variant="outlined"
            className="btnAddOutputType"
            onClick={toggleShowCreateOutputTypeModal}
          >
            Add Output Type
          </Button>
        ) : null}

        {loading ? (
          <LoaderAbsoluteCentred loading={loading} />
        ) : (
          <AnimationWrapper>
            <div>
              {outputTypes.map((output) => {
                return (
                  <OutputTypeRow
                    outputType={output}
                    canEdit={canEdit}
                    onRowClick={onOutputTypeClick}
                  />
                );
              })}
              {(!outputTypes || !outputTypes.length) && (
                <WidgetNoResultsPlaceholder text="No output types" icon={HeaderIcon} flip={true} />
              )}
            </div>
          </AnimationWrapper>
        )}

        <ModalOutputTypeCreate
          open={showCreateOutputTypeModal}
          onCancelCallback={toggleShowCreateOutputTypeModal}
          onCompleteCallback={toggleShowCreateOutputTypeModal}
        />

        {/* Update */}
        {outputTypeToUpdate && (
          <ModalOutputTypeUpdate
            open={outputTypeToUpdate !== undefined}
            onCancelCallback={hideOutputTypeUpdate}
            onCompleteCallback={hideOutputTypeUpdate}
            outputType={outputTypeToUpdate}
            canEdit={canEdit}
          />
        )}
      </DivWrapper>
    </WidgetSectionBase>
  );
};

function OutputTypeRow({
  outputType,
  canEdit,
  onRowClick,
}: {
  outputType: IOutputType;
  canEdit: boolean;
  onRowClick(summaryOutputType: IOutputType): void;
}) {
  const dispatch = useDispatch();
  const imageUrl = useFileSourceSingleHook({
    fileId: outputType.mainImageId || "",
    fileType: FileTypeEnum.Image,
  });
  const mainImage = useSelector((store: RootState) => selectorGetFileById(store, outputType.mainImageId));
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [showInputsModal, setShowInputsModal] = useState(false);
  const [showOutputTypeDefinitions, setShowOutputTypeDefinitions] = useState(false);
  const [downloadingExport, setDownloadingExport] = useState(false);

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  function onEditClick() {
    setMenuAnchorEl(undefined);
    onRowClick(outputType);
  }

  async function downloadOutputType() {
    setDownloadingExport(true);
    setMenuAnchorEl(undefined);

    try {
      
      var OutputTypeInputFields = await dispatch(
        fetchSearchOutputTypeInputFields({ 
          summaryOutputTypeId: outputType?.summaryOutputTypeId, 
          pageNumber: 1, 
          pageSize: 500 
        })
      );

      var OutputTypeInputFieldsListValues = await dispatch(
        fetchSearchOutputTypeInputFieldListValues({ 
          summaryOutputTypeId: outputType?.summaryOutputTypeId, 
          pageNumber: 1, 
          pageSize: 500 
        })
      );
      
      var outputObj = {
        outputType,
        OutputTypeInputFields,
        OutputTypeInputFieldsListValues,
        files: [mainImage],
      };

      DownloadObjectAsFile(outputObj, `${outputType.name}.json`);
    } finally {
      setDownloadingExport(false);
    }
  }

  function toggleShowInputsModal() {
    setMenuAnchorEl(undefined);
    setShowInputsModal(!showInputsModal);
  }

  async function toggleClassDefinitions() {
    setShowOutputTypeDefinitions(!showOutputTypeDefinitions);
    setMenuAnchorEl(undefined);
  }

  return (
    <>
      <Grid container className="outputTypeWrapper">
        <LoaderAbsoluteCentred loading={downloadingExport} />
        <Grid item xs={3} md={2} lg={1}>
          <div className="outputTypeImageWrapper">
            <div className="OutputTypeImage" style={{ backgroundImage: `url("${imageUrl}")` }} />
          </div>
        </Grid>
        <Grid item xs={6} md={7} lg={8} style={{ paddingLeft: 8 }}>
          <div className="outputTypeMainWrapper">
            <div>
              <Typography variant="body1" noWrap={true}>
                {outputType.name}
              </Typography>
              <Typography style={{ opacity: 0.8, fontSize: "85%" }} noWrap={true}>
                {outputType.description}
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid item xs={3} style={{ textAlign: "right" }}>
          {canEdit ? (
            <>
              <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
                <MenuIcon />
              </IconButton>

              <Menu
                id="simple-menu"
                anchorEl={menuAnchorEl}
                keepMounted
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(undefined)}
              >
                <MenuItem onClick={onEditClick}>
                  <ListItemIcon>
                    <EditSummaryOutputTypeIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Edit" />
                </MenuItem>
                <MenuItem onClick={toggleShowInputsModal}>
                  <ListItemIcon>
                    <ManageFieldsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Manage Fields" />
                </MenuItem>
                <MenuItem onClick={toggleClassDefinitions}>
                  <ListItemIcon>
                    <ClassDefinitionsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Class Definitions" />
                </MenuItem>
                <MenuItem onClick={downloadOutputType}>
                  <ListItemIcon>
                    <DownloadIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Export" />
                </MenuItem>
              </Menu>
            </>
          ) : null}
        </Grid>
      </Grid>

      {showInputsModal && (
        <ModalOutputTypeInputFields
          open={showInputsModal}
          onCloseCallback={toggleShowInputsModal}
          summaryOutputTypeId={outputType.summaryOutputTypeId}
          canEdit={canEdit}
        />
      )}
      {showOutputTypeDefinitions && (
        <ModalOutputTypeDefinitions
          open={showOutputTypeDefinitions}
          onCancelCallback={() => setShowOutputTypeDefinitions(false)}
          summaryOutputTypeId={outputType?.summaryOutputTypeId ?? ""}
        />
      )}
    </>
  );
}

export default OutputTypeEnquiryContainer;
