import { IUserRole } from '../../utilities/types/UserRole'
import { ACTIONS_USER_ROLES, IFetchPaginationProps } from './types';
import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, AddQueryStringsToUrl, CheckStatus, ShowExceptionAsMessage } from '../../utilities/ApiUtils';
import { selectorGetUserRoleById } from './selectors';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';


export const receiveUserRoles = (userRoles: IUserRole[]) => {

    var byIdObjectToDispatch: { [key: string]: IUserRole } = {};

    for (var i = 0; i < userRoles.length; i++) {
        byIdObjectToDispatch[userRoles[i].userRoleId] = userRoles[i];
    }

    return {
        type: ACTIONS_USER_ROLES.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};

export const requestDeleteUserRoles = (userRole: IUserRole) => ({
    type: ACTIONS_USER_ROLES.DELETE,
    byId: { [userRole.userRoleId]: userRole }
});

export const fetchUserRolesByIdIfNeeded = (userRoleId: string): AppThunk<Promise<void>> => async (dispatch, getState) => {

    if (!selectorGetUserRoleById(getState(), userRoleId)) {
        await dispatch(fetchSearchUserRoles({ userRoleId, pageNumber: 1, pageSize: 1 }));
    }

    return;
}


export interface IFetchSearchUserRoles extends IFetchPaginationProps {
    userRoleId?: string,
    text?: string,
}


export const fetchSearchUserRoles = ({ pageSize, pageNumber, userRoleId, text }: IFetchSearchUserRoles): AppThunk<Promise<IUserRole[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/userRoles`, {
            pageSize,
            pageNumber,
            userRoleId,
            text,
        }), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userroles, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.userRoles) {

            dispatch(receiveUserRoles(parsedResp.data.userRoles));
            return parsedResp.data.userRoles;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching user Roles.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error retrieving user details.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchCreateUserRoleProps {
    userRoleDescription: string,
}


export const fetchCreateUserRole = (roleToCreate: IFetchCreateUserRoleProps): AppThunk<Promise<IUserRole>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/userRoles`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(roleToCreate),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.userRoles && parsedResp.data.userRoles.length) {
            dispatch(receiveUserRoles(parsedResp.data.userRoles));
            return parsedResp.data.userRoles[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating userRole.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating userRole.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}

export interface IFetchUpdateUserRoleProps {
    userRoleId: string,
    userRoleDescription: string,
}

export const fetchUpdateUserRole = (props: IFetchUpdateUserRoleProps): AppThunk<Promise<IUserRole>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/userRoles`, {
            method: 'PUT',
            headers: headers,
            body: PrepareBody(props),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.userRoles && parsedResp.data.userRoles.length) {
            dispatch(receiveUserRoles(parsedResp.data.userRoles));
            return parsedResp.data.userRoles[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error updating userRole.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error updating userRole.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}

export interface IFetchDeleteUserRoleProps {
    userRoleId: string,
}

export const fetchDeleteUserRole = (props: IFetchDeleteUserRoleProps): AppThunk<Promise<IUserRole>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/userRoles`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.userRoles && parsedResp.data.userRoles.length) {
            dispatch(requestDeleteUserRoles(parsedResp.data.userRoles[0]));
            return parsedResp.data.userRoles[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting user role.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting user role.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}

export interface IFetchCreateUserToRoleMappingProps {
    userRoleMappingIds: string[],
    userDetailId: string,
}