import React from "react";
import { Grid, Typography } from "@mui/material";
import { Container } from "@mui/material";
import { RouteComponentProps } from "react-router-dom";
import { useFetchOutputTypesPageHook } from "../Hooks";
import AnimationWrapper from "../../generic/animations/AnimationWrapper";
import { WidgetNoResultsPlaceholder } from "../../generic/widgets/WidgetNoResultsPlaceholder";
import SearchIcon from "@mui/icons-material/Search";
import {
  selectorGetOutputTypes,
  sortOutputTypesByNameAsc,
} from "../../../redux/outputType/selectors";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { selectorGetUserPermissionOfType } from "../../../redux/userPermission/selectors";
import { GetUserId } from "../../../utilities/ApiUtils";
import { UserPermissionTypeEnum } from "../../../utilities/types/UserPermission";
import AddNewOutputWidget from "../AddNewOutputWidget";
import OutputTypeSearchResultWidget from "../OutputTypeSearchResultWidget";

const PageOutputListingSearch = (
  routeProps: RouteComponentProps<any>
) => {
  useFetchOutputTypesPageHook({
    pageNumber: 1,
    pageSize: 50,
    minPageNumberToFetch: 1,
  });
  const canCreateOutputs = useSelector((store: RootState) =>
    selectorGetUserPermissionOfType(
      store,
      GetUserId(),
      UserPermissionTypeEnum.ServicesOutputTypeCreate
    )
  );

  const sOTStateResults = useSelector((store: RootState) =>
    selectorGetOutputTypes(store)
  );

  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid item xs={12} style={{ marginTop: 16 }}>
          <Typography variant="h5">Search Outputs</Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={3}
            style={{ marginTop: 16 }}
            id="search-results-wrapper"
          >
            {canCreateOutputs ? (
              <AnimationWrapper key={"Test"}>
                <Grid item xs={12} sm={6} md={4}>
                  <AddNewOutputWidget />
                </Grid>
              </AnimationWrapper>
            ) : null}

            {sOTStateResults.length ? (
              sortOutputTypesByNameAsc(sOTStateResults).map(
                (sOTStateResult, i) => {
                  //if (i >= 9) return null;

                  return (
                    <AnimationWrapper key={sOTStateResult.summaryOutputTypeId}>
                      <Grid item xs={12} sm={6} md={4}>
                        <OutputTypeSearchResultWidget
                          outputType={sOTStateResult}
                          loading={false}
                        />
                      </Grid>
                    </AnimationWrapper>
                  );
                }
              )
            ) : (
              <Grid item xs={12}>
                <WidgetNoResultsPlaceholder
                  text="No results"
                  icon={SearchIcon}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PageOutputListingSearch;
