import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { IUserRole } from "../../utilities/types/UserRole";
import { fetchSearchUserRoles } from "../../redux/userRole/actions";
import { IUserToRoleMapping } from "../../utilities/types/UserToRoleMapping";
import { fetchSearchUserToRoleMappings } from "../../redux/userToRoleMapping/actions";
import {  IFetchUserDetailsPaginationType, IFetchPaginationProps } from "../../redux/userRole/types";

interface IUseFetchUserRolesPageHookProps extends IFetchPaginationProps{
  minPageNumberToFetch:number,
}

export const useFetchUserRolesPageHook = ({
  pageNumber,
  minPageNumberToFetch,
  pageSize,
}: IUseFetchUserRolesPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IUserRole[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<IUserRole[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if ( pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        // Retrieve models
        var resp: any = await dispatch(
          fetchSearchUserRoles({
            pageSize,
            pageNumber,
          })
        );

        if (resp && resp.success && resp.data && resp.data.userRoles && resp.data.userRoles.length) {
          setMorePages(resp.data.userRoles.length >= pageSize);
          setLastResultSet(resp.data.userRoles);
        } else {
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, dispatch, pageNumber, pageSize]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.userRoleId === x.userRoleId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};


interface IUseFetchUserRolesPageHookProps extends IFetchUserDetailsPaginationType{}

export const useFetchUserToUserRolesPageHook = ({
  pageSize,
  pageNumber,
  minPageNumberToFetch,
  userDetailId,
}: IUseFetchUserRolesPageHookProps) => {
  const dispatch = useDispatch();
  const [fetching, setFetching] = useState<boolean>(false);
  const [morePages, setMorePages] = useState<boolean>(false);
  const [lastResultSet, setLastResultSet] = useState<IUserToRoleMapping[]>([]);
  const [allResultsSet, setAllResultsSet] = useState<IUserToRoleMapping[]>([]);

  useEffect(() => {
    // This allows us to prevent initial page load fetches by setting page number to something like zero
    if ( pageNumber < minPageNumberToFetch) {
      return;
    }

    (async () => {
      setFetching(true);

      try {
        // Retrieve models
        var resp: any = await dispatch(
          fetchSearchUserToRoleMappings({
            pageSize,
            pageNumber,
            userDetailId,
          })
        );

        if (resp && resp.success && resp.data && resp.data.userToRoleMappings && resp.data.userToRoleMappings.length) {
          setMorePages(resp.data.userToRoleMappings.length >= pageSize);
          setLastResultSet(resp.data.userToRoleMappings);
        } else {
          setMorePages(false);
        }
      } finally {
        setFetching(false);
      }
    })();
  }, [minPageNumberToFetch, dispatch, pageNumber, pageSize, userDetailId]);

  // Merge any new result sets with existing
  useEffect(() => {
    if (lastResultSet.some((x) => !allResultsSet.some((y) => y.userToRoleMappingId === x.userToRoleMappingId))) {
      setAllResultsSet(allResultsSet.concat(lastResultSet));
    }
  }, [lastResultSet, allResultsSet]);

  return {
    lastResultSet,
    fetching,
    morePages,
    setAllResultsSet,
    allResultsSet,
  };
};
