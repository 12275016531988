import { RootState } from "..";
import { IOutputTypeInputField } from "../../utilities/types/OutputTypeInputField";


export function selectorGetOutputTypeInputFieldsBySummaryOutputTypeId(state: RootState, summaryOutputTypeId: string): IOutputTypeInputField[] {

    if (!state.summaryOutputTypeInputFields || !state.summaryOutputTypeInputFields.byId) return [];

    return sortOutputTypeInputFieldsByOrderNumberAscending(Object.values(state.summaryOutputTypeInputFields.byId).filter(x => x.summaryOutputTypeId === summaryOutputTypeId));
}

export function selectorGetOutputTypeInputFieldById(state: RootState, summaryOutputTypeInputFieldId: string): IOutputTypeInputField | undefined {

    if (!state.summaryOutputTypeInputFields || !state.summaryOutputTypeInputFields.byId) return undefined;

    return state.summaryOutputTypeInputFields.byId[summaryOutputTypeInputFieldId];
}


export function selectorGetOutputTypeInputFields(state: RootState) {

    if (!state.summaryOutputTypeInputFields || !state.summaryOutputTypeInputFields.byId) return [];

    return Object.values(state.summaryOutputTypeInputFields.byId);
}


export function selectorGetOutputTypeInputFieldTableNamesByOutputTypeId(state: RootState, summaryOutputTypeId: string) {

    var summaryOutputTypeInputFields = selectorGetOutputTypeInputFieldsBySummaryOutputTypeId(state, summaryOutputTypeId);
    var tableNames = summaryOutputTypeInputFields.filter(x => x.tableName && x.tableName !== '').map(x => x.tableName);
    var distinctTableNames = new Set(tableNames);

    return Array.from(distinctTableNames.values());
}


// function sortSummaryOutputTypeInputFieldsByOrderNumberDescending(summaryOutputTypeInputFields: IOutputTypeInputField[]) {
//     return summaryOutputTypeInputFields.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortOutputTypeInputFieldsByOrderNumberAscending(summaryOutputTypeInputFields: IOutputTypeInputField[]) {
    return summaryOutputTypeInputFields.sort((a, b) => a.orderNumber - b.orderNumber);
}