import { IOutputTypeInputField } from "../../utilities/types/OutputTypeInputField";


export const ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD = {
    INVALIDATE: 'INVALIDATE_SUMMARY_OUTPUT_TYPE_INPUT_FIELDS',
    RECEIVE: 'RECEIVE_SUMMARY_OUTPUT_TYPE_INPUT_FIELDS',
    DELETE: 'DELETE_SUMMARY_OUTPUT_TYPE_INPUT_FIELD',
    UPDATE: 'UPDATE_SUMMARY_OUTPUT_TYPE_INPUT_FIELDS',
}

interface ActionSummaryOutputTypeInputFieldsInvalidate {
    type: typeof ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD.INVALIDATE,
    byId: { [key: string]: IOutputTypeInputField },
}

interface ActionSummaryOutputTypeInputFieldsReceive {
    type: typeof ACTIONS_SUMMARY_OUTPUT_TYPE_INPUT_FIELD.RECEIVE
    byId: { [key: string]: IOutputTypeInputField },
}

export interface SummaryOutputTypeInputFieldState {
    byId: { [key: string]: IOutputTypeInputField },
}

export enum SummaryOutputTypeInputFieldSearchOrderTypeEnum {
    CreatedDateDesc = 10,
    CreatedDateAsc = 20,
    VersionNumberAsc = 30,
    VersionNumberDesc = 40,
}

export type ActionTypesSummaryOutputTypeInputField = ActionSummaryOutputTypeInputFieldsReceive | ActionSummaryOutputTypeInputFieldsInvalidate;