import React, { useEffect } from "react";

import WidgetModalBase from "../../generic/widgets/modals/WidgetModalBase";
import MapIcon from "@mui/icons-material/MapRounded";

import { DialogContent as MuiDialogContent, DialogActions, Button } from "@mui/material";
import JobCreateSolverJobStep from "../../solverJobs/JobCreateSolverJobStep";
import useJobCreateState from "../../solverJobs/WizardState";

export interface IModalCreateSolverJobProps {
  open: boolean;
  onCancelCallback(): void;
  assetJobId: string;
}

function ModalCreateSolverJob({ onCancelCallback, open, assetJobId }: IModalCreateSolverJobProps) {
  const setPrerequisites = useJobCreateState((s) => s.setPrerequisites);

  useEffect(() => {
    if (assetJobId) setPrerequisites("assetJobIds", [assetJobId]);
  }, [assetJobId, setPrerequisites]);

  return (
    <WidgetModalBase
      handleCancel={onCancelCallback}
      open={open}
      title={"Create Solver Job"}
      subtitle={""}
      headerIcon={<MapIcon />}
      style={{ maxWidth: 800, width: "100%" }}
    >
      <MuiDialogContent>
        <JobCreateSolverJobStep isFromCreateModal onCompleteCallback={onCancelCallback} />
      </MuiDialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onCancelCallback}>
          Close
        </Button>
      </DialogActions>
    </WidgetModalBase>
  );
}

export default ModalCreateSolverJob;
