import { IconButton, Typography, Divider, TextField, styled } from "@mui/material";

import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetOutputTypeInputFieldsBySummaryOutputTypeId } from "../../redux/outputTypeInputField/selectors";
import { IOutputType, TOutputTypeInputFieldValue } from "../../utilities/types/OutputType";
import { IOutputTypeInputField } from "../../utilities/types/OutputTypeInputField";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { useFetchOutputTypeInputFieldListValuesPageHook } from "../outputTypeInputFieldListValue/Hooks";
import { useFetchOutputTypeInputFieldsPageHook } from "./Hooks";
import OutputTypeInputFieldDisplay from "./OutputTypeInputFieldDisplay";

const DivWrapper = styled("div")(({ theme }) => ({
  position: "relative",
  padding: "8px 12px",
  "& .headerWrapper": {
    marginBottom: theme.spacing(2),
  },
  "& .inputFieldWrapper": {
    display: "flex",
    flexDirection: "row",
    flexBasis: "100%",
    margin: theme.spacing(2),
  },
  "& .inputFieldWrapperButton": {
    flexBasis: "25%",
    justifyContent: "flex-end",
    textAlign: "right",
  },
  "& .inputFieldWrapperField": {
    flexBasis: "70%",
    maxWidth: "70%",
  },
}));

interface IOutputTypeInputFieldTabPanelProps {
  outputType: IOutputType;
  defaultValueOverrides: { [key: string]: TOutputTypeInputFieldValue };
  onValueChangeCallback: (
    summaryOutputTypeId: string,
    fieldId: string,
    value: TOutputTypeInputFieldValue
  ) => void;
  onLoadChange: (id: string, loading: boolean) => void;
  onInputFieldsChanged: (fields: IOutputTypeInputField[]) => void;
  showDivider: boolean;
}

const OutputTypeInputFieldTabPanel = ({
  outputType,
  defaultValueOverrides,
  onValueChangeCallback,
  onLoadChange,
  onInputFieldsChanged,
  showDivider = false,
}: IOutputTypeInputFieldTabPanelProps) => {

  const summaryOutputTypeId = outputType.summaryOutputTypeId;
  const summaryOutputTypeInputFields = useSelector((store: RootState) =>
    selectorGetOutputTypeInputFieldsBySummaryOutputTypeId(store, summaryOutputTypeId)
  );
    // Group the fields by `Tablename`
    const groupedFields = summaryOutputTypeInputFields.reduce((groups, field) => {
    const group = groups[field.tableName] || [];
    group.push(field);
    groups[field.tableName] = group;
    return groups;
  }, {} as { [Tablename: string]: IOutputTypeInputField[] });
  
  const [hasLoadedField, setHasLoadedField] = useState(false);

  const hookProps = useMemo(
    () => ({ pageNumber: 1, pageSize: 30, minPageNumberToFetch: 1, outputTypeId: summaryOutputTypeId }),
    [summaryOutputTypeId]
  );
  const { fetching: fetchingFields } = useFetchOutputTypeInputFieldsPageHook(hookProps);
  const { fetching: fetchingFieldValues } = useFetchOutputTypeInputFieldListValuesPageHook(hookProps);
  const loading = fetchingFields || fetchingFieldValues;

  useEffect(() => {
    if (hasLoadedField || fetchingFieldValues) return;
    if (!summaryOutputTypeInputFields.length) return;
    onInputFieldsChanged(summaryOutputTypeInputFields);
    setHasLoadedField(true);
  }, [hasLoadedField, onInputFieldsChanged, loading]);

  // Ensure the page actions are disabled while loading
  useEffect(() => {
    onLoadChange(summaryOutputTypeId, loading);
  }, [onLoadChange, summaryOutputTypeId, loading]);

  return (
    <>
      {showDivider && <Divider style={{ marginTop: 20, marginBottom: 16 }} />}
      <DivWrapper>
        <LoaderAbsoluteCentred loading={loading} />
        <div className="headerWrapper">
          <Typography variant="subtitle1" style={{ textTransform: "capitalize" }}>
            {outputType.name}
          </Typography>
        </div>
        <div>
          <div className="inputFieldWrapper">
            <div className="inputFieldWrapperField">
              <TextField
                type="text"
                label="Name"
                defaultValue={defaultValueOverrides["displayName"]?.value}
                onChange={(e: any) =>
                  onValueChangeCallback(outputType.summaryOutputTypeId, "displayName", {
                    value: e?.target?.value,
                  })
                }
                fullWidth
                variant="outlined"
              />
            </div>
          </div>

          {
            Object.entries(groupedFields).map(([Tablename, fields]) => (
              <div key={Tablename}>
              <Typography variant="overline" component="p" style={{ marginBottom: 8 }}>
                {Tablename}
              </Typography>
                {fields.map((summaryOutputTypeInputField) => (
                  <div
                    key={summaryOutputTypeInputField.summaryOutputTypeInputFieldId}
                    className="inputFieldWrapper"
                  >
                    <div className="inputFieldWrapperField">
                      <OutputTypeInputFieldDisplay
                        outputTypeInputField={summaryOutputTypeInputField}
                        onValueChangeCallback={(fieldId, value) =>
                          onValueChangeCallback(outputType.summaryOutputTypeId, fieldId, value)
                        }
                        overriddenDefaultValue={
                          defaultValueOverrides[summaryOutputTypeInputField.summaryOutputTypeInputFieldId]
                        }
                      />
                    </div>
                    <div className="inputFieldWrapperButton">
                      <Tooltip title={summaryOutputTypeInputField.description} placement="right">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                ))}
              </div>
            ))
            }
        </div>
      </DivWrapper>
    </>
  );
};

export default OutputTypeInputFieldTabPanel;
