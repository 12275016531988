import { Action, applyMiddleware, combineReducers, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk, { ThunkAction } from "redux-thunk";
import { assetInputFieldsReducer } from "./assetInputField/reducers";
import { assetInputFieldCategoriesReducer } from "./assetInputFieldCategory/reducers";
import { assetInputFieldListValuesReducer } from "./assetInputFieldListValue/reducers";
import { commentsReducer } from "./comment/reducers";
import { componentsReducer } from "./component/reducers";
import { costBundlesReducer } from "./costBundle/reducers";
import { decisionsReducer } from "./decision/reducers";
import { decisionAssetInputFieldMappingsReducer } from "./decisionAssetInputFieldMapping/reducers";
import { discussionsReducer } from "./discussion/reducers";
import { entityPermissionsReducer } from "./entityPermission/reducers";
import { eventsReducer } from "./event/reducers";
import { failureModesReducer } from "./failureMode/reducers";
import { favouritesReducer } from "./favourite/reducers";
import { feedbacksReducer } from "./feedback/reducers";
import { filesReducer } from "./file/reducers";
import { functionsReducer } from "./function/reducers";
import { functionalFailuresReducer } from "./functionalFailure/reducers";
import { identifiersReducer } from "./identifier/reducers";
import { identifierMappingsReducer } from "./identifierMapping/reducers";
import { messageReducer } from "./message/reducers";
import { modelsReducer } from "./model/reducers";
import { modelHistoriesReducer } from "./modelHistory/reducers";
import { plansReducer } from "./plan/reducers";
import { solversReducer } from "./solver/reducers";
import { solverDetailsReducer } from "./solverDetails/reducers";
import { solverHistoriesReducer } from "./solverHistory/reducers";
import { solverInputFieldsReducer } from "./solverInputField/reducers";
import { solverInputFieldListValuesReducer } from "./solverInputFieldListValue/reducers";
import { solverJobsReducer } from "./solverJob/reducer";
import { outputJobReducer } from "./outputJob/reducers";
import { solverJobTasksReducer } from "./solverJobTask/reducers";
import { solverOutputTypeMappingsReducer } from "./outputTypeMapping/reducers";
import { OutputTypeMappingsReducer } from "./solverOutputTypeMapping/reducers";
import { SolverUIResultSetsReducer } from "./solverUIResultSet/reducers";
import { SolverUIResultSetChartsReducer } from "./solverUIResultSetChart/reducers";
import { summaryOutputTypesReducer } from "./outputType/reducers";
import { summaryOutputTypeInputFieldsReducer } from "./outputTypeInputField/reducers";
import { summaryOutputTypeInputFieldListValuesReducer } from "./outputTypeInputFieldListValue/reducers";
import { systemReducer } from "./systemState/reducers";
import { tasksReducer } from "./task/reducers";
import { taskApplicabilitiesReducer } from "./taskApplicability/reducers";
import { taskImpactsReducer } from "./taskImpact/reducers";
import { termsAgreementsReducer } from "./termsAgreement/reducers";
import { userContributionsReducer } from "./userContribution/reducers";
import { userDetailsReducer } from "./userDetail/reducers";
import { userRolesReducer } from "./userRole/reducers";
import { userPermissionsReducer } from "./userPermission/reducers";
import { userRolePermissionsReducer } from "./userRolePermission/reducers";
import { userToRoleMappingReducer } from "./userToRoleMapping/reducers";
import { workflowsReducer } from "./workflow/reducers";
import { insufficientPermissionReducer } from "./insufficientPermission/reducer";
import { assetJobsReducer } from "./assetJob/reducer";
import { assetJobTasksReducer } from "./assetJobTask/reducer";

const rootReducer = combineReducers({
  assetInputFields: assetInputFieldsReducer,
  assetInputFieldCategories: assetInputFieldCategoriesReducer,
  assetInputFieldListValues: assetInputFieldListValuesReducer,
  assetJobs: assetJobsReducer,
  assetJobTasks: assetJobTasksReducer,
  comments: commentsReducer,
  components: componentsReducer,
  costBundles: costBundlesReducer,
  decisionAssetInputFieldMappings: decisionAssetInputFieldMappingsReducer,
  decisions: decisionsReducer,
  discussions: discussionsReducer,
  entityPermissions: entityPermissionsReducer,
  events: eventsReducer,
  failureModes: failureModesReducer,
  favourites: favouritesReducer,
  feedbacks: feedbacksReducer,
  files: filesReducer,
  functionalFailures: functionalFailuresReducer,
  functions: functionsReducer,
  identifiers: identifiersReducer,
  identifierMappings: identifierMappingsReducer,
  messages: messageReducer,
  models: modelsReducer,
  modelHistories: modelHistoriesReducer,
  plans: plansReducer,
  solvers: solversReducer,
  solverDetails: solverDetailsReducer,
  solverHistories: solverHistoriesReducer,
  solverInputFields: solverInputFieldsReducer,
  solverInputFieldListValues: solverInputFieldListValuesReducer,
  solverJobs: solverJobsReducer,
  summaryOutputJob: outputJobReducer,
  solverJobTasks: solverJobTasksReducer,
  solverOutputTypeMappings: solverOutputTypeMappingsReducer,
  solverSummaryOutputTypeMappings: OutputTypeMappingsReducer,
  SolverUIResultSets: SolverUIResultSetsReducer,
  SolverUIResultSetCharts: SolverUIResultSetChartsReducer,
  summaryOutputTypes: summaryOutputTypesReducer,
  summaryOutputTypeInputFields: summaryOutputTypeInputFieldsReducer,
  summaryOutputTypeInputFieldListValues: summaryOutputTypeInputFieldListValuesReducer,
  system: systemReducer,
  tasks: tasksReducer,
  taskApplicabilities: taskApplicabilitiesReducer,
  taskImpacts: taskImpactsReducer,
  termsAgreements: termsAgreementsReducer,
  userDetails: userDetailsReducer,
  userRoles: userRolesReducer,
  userRolePermissions: userRolePermissionsReducer,
  userToRoleMappings: userToRoleMappingReducer,
  userPermissions: userPermissionsReducer,
  userContributions: userContributionsReducer,
  workflows: workflowsReducer,
  insufficientPermission: insufficientPermissionReducer,
});

const middleware: any = [thunk];
// if (process.env.NODE_ENV !== 'production') {
middleware.push(createLogger());
// }

export const composeEnhancers = (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

/* Retrieve persisted state if applicable: https://stackoverflow.com/a/37690899/522859 */
// const persistedState = localStorage.getItem('reduxState') ? JSON.parse(localStorage.getItem('reduxState') || '') : {};

const store = createStore(
  rootReducer,
  // { cartProducts: persistedState.cartProducts, carts: persistedState.carts },
  composeEnhancers(applyMiddleware(...middleware))
);

/* Save the state: we do this so that we can rehydrate on refresh etc */
store.subscribe(() => {
  localStorage.setItem("reduxState", JSON.stringify(store.getState()));
});

export { store as default };

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export type RootState = ReturnType<typeof rootReducer>;
