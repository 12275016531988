import React, { useState } from "react";
import {
  Typography,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Paper,
  InputBase,
  Divider,
  Chip,
  styled,
} from "@mui/material";
import HeaderColor from "@mui/material/colors/blueGrey";

import { ISolverJob, SolverJobStatusEnum } from "../../utilities/types/SolverJob";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import HeaderIcon from "@mui/icons-material/Timeline";
import { useFetchSolverJobsPageHook } from "./Hooks";
import { FileTypeEnum } from "../../utilities/types/File";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import AnimationWrapper from "../generic/animations/AnimationWrapper";
import { useFileSourceSingleHook } from "../file/Hooks";
import SearchIcon from "@mui/icons-material/Search";
import CheckboxIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import CheckboxIconFilled from "@mui/icons-material/RadioButtonCheckedRounded";
import { AddSpacesToSentence, ToTitleCase } from "../../utilities/Helpers";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { selectorGetSolverById } from "../../redux/solver/selectors";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import { GetSolverJobLinkBySolverJobId } from "../../routes/RouteLinkHelpers";
import { sortSolverJobsByCreatedDescending } from "../../redux/solverJob/selectors";
import { selectorGetUserPermissionIsAdmin } from "../../redux/userPermission/selectors";
import { GetUserId } from "../../utilities/ApiUtils";
import ModalCreateSolverJob from "../solver/modals/ModalCreateSolverJob";
import { displayFormattedDate } from "../../utilities/formatter";

const DivWrapper = styled("div")(({ theme }) => ({
  minHeight: 500,
  height: "100%",

  "& .solverJobWrapper": {
    border: "1px solid #EEE",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    position: "relative",
  },
  "& .solverJobImageWrapper": {
    width: "100%",
    height: "80%",
    textAlign: "center",
    display: "flex",
    justifyContent: "space-around",
    position: "relative",
    maxWidth: "100%",
    left: 0,
    bottom: 0,
    top: 0,
    overflow: "hidden",
    borderRight: "1px solid rgba(0,0,0,0.1)",
  },
  "& .solverJobImage": {
    objectFit: "cover",
    filter: "sepia(20%)",
    height: "100%",
    width: "100%",
    borderRadius: `${theme.shape.borderRadius}px`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
  "& .solverJobImageGrid": {
    display: "flex",
    alignItems: "center",
  },
  "& .creatorBar": {
    width: 5,
    height: "80%",
    marginLeft: theme.spacing(1),
    borderRadius: 28,
    background: HeaderColor[800],
  },
  "& .solverJobMainWrapper": {
    marginLeft: theme.spacing(1),
  },

  "& .searchRoot": {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    boxShadow: "none",
    border: "1px solid #DEDEDE",
  },
  "& .input": {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  "& .iconButton": {
    padding: 10,
  },
  "& .divider": {
    height: 28,
    margin: 4,
  },
  "& .solverJobsWrapper": {
    maxHeight: 400,
    overflowY: "auto",
  },
  "& .fade": {
    animation: "$fade 2000ms infinite",
  },
  "@keyframes fade": {
    "50%": {
      opacity: 0.3,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

type ISolverJobEnquiryContainerProps  = {
  canEdit: boolean;
  assetJobId?: string;
};

const SolverJobsEnquiryContainer  = ({ canEdit, assetJobId }: ISolverJobEnquiryContainerProps ) => {
  const [searchStatus, setSearchStatusInternal] = useState<SolverJobStatusEnum>();
  const [searchText, setSearchTextInternal] = useState<string>();
  const [pageNumber, setPageNumber] = useState(1);

  const {
    fetching,
    lastResultSet: solverJobs,
    morePages,
  } = useFetchSolverJobsPageHook({
    pageNumber: pageNumber,
    pageSize: 20,
    minPageNumberToFetch: 1,
    status: searchStatus,
    text: searchText,
    assetJobId,
  });

  function setSearchStatus(status?: SolverJobStatusEnum) {
    setSearchStatusInternal(status);
    setPageNumber(1);
  }

  function setSearchText(text?: string) {
    setSearchTextInternal(text);
    setPageNumber(1);
  }

  return (
    <SolverJobEnquiryContainerDisplay
      canEdit={canEdit}
      solverJobs={sortSolverJobsByCreatedDescending(solverJobs)}
      loading={fetching}
      onSetSearchStatus={(newStatus: SolverJobStatusEnum) => setSearchStatus(newStatus)}
      onSetSearchText={(newText: string) => setSearchText(newText)}
      setPageNumber={setPageNumber}
      pageNumber={pageNumber}
      hasMorePages={morePages}
      assetJobId={assetJobId}
    />
  );
};

type ISolverJobEnquiryContainerDisplayProps = {
  canEdit: boolean;
  solverJobs: ISolverJob[];
  loading: boolean;
  onSetSearchStatus(newStatus?: SolverJobStatusEnum): void;
  onSetSearchText(newText?: string): void;
  pageNumber: number;
  setPageNumber(page: number): void;
  hasMorePages: boolean;
  assetJobId?: string;
};

const SolverJobEnquiryContainerDisplay = ({
  solverJobs,
  hasMorePages,
  setPageNumber,
  pageNumber,
  loading,
  canEdit,
  onSetSearchStatus,
  onSetSearchText,
  assetJobId,
}: ISolverJobEnquiryContainerDisplayProps) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element>();
  const [statusFilter, setJobStatusToFilterBy] = useState<SolverJobStatusEnum>();
  const [searchText, setSearchTextFilter] = useState<string>();
  const [showCreateSolverJobModal, setShowCreateSolverJobModal] = useState<boolean>(false);

  function updateJobStatusViaMenu(newStatus?: SolverJobStatusEnum) {
    setJobStatusToFilterBy(newStatus);
    setMenuAnchorEl(undefined);
    onSetSearchStatus(newStatus);
  }

  const handleMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  function onSearchTextChangeHandler(event: any) {
    setSearchTextFilter(event.target.value);
  }

  function onSearchClick(e: any) {
    e.preventDefault();
    onSetSearchText(searchText);
    return false;
  }

  function toggleCreateSolverModal() {
    setShowCreateSolverJobModal((prev) => !prev);
  }

  return (
    <WidgetSectionBase
      headerText="Solver Jobs"
      subheaderText="Browse submitted Solver Jobs."
      headerIcon={<HeaderIcon />}
      fullWidthHeader={true}
      style={{ height: "100%" }}
    >
      <DivWrapper>
        <Paper component="form" className="searchRoot" onSubmit={onSearchClick}>
          <Button className="iconButton" aria-label="menu" onClick={handleMenuClick} size="small">
            {ToTitleCase(statusFilter ? SolverJobStatusEnum[statusFilter].toString() : "All")}
          </Button>
          <Divider className="divider" orientation="vertical" />
          <Menu
            id="simple-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={() => setMenuAnchorEl(undefined)}
          >
            <div style={{ paddingRight: 16, paddingLeft: 16 }}>
              <Typography variant="overline">Status Filters</Typography>
            </div>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(undefined)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === undefined} />
              </ListItemIcon>
              <ListItemText primary="None" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(SolverJobStatusEnum.Pending)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === SolverJobStatusEnum.Pending} />
              </ListItemIcon>
              <ListItemText primary="Pending" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(SolverJobStatusEnum.Progress)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === SolverJobStatusEnum.Progress} />
              </ListItemIcon>
              <ListItemText primary="In Progress" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(SolverJobStatusEnum.Failed)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === SolverJobStatusEnum.Failed} />
              </ListItemIcon>
              <ListItemText primary="Failed" />
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => updateJobStatusViaMenu(SolverJobStatusEnum.Complete)}>
              <ListItemIcon>
                <StatusFilterIcon checked={statusFilter === SolverJobStatusEnum.Complete} />
              </ListItemIcon>
              <ListItemText primary="Complete" />
            </MenuItem>
          </Menu>
          <InputBase
            className="input"
            placeholder="Search Solver Jobs"
            inputProps={{ "aria-label": "Search Solver Jobs" }}
            onChange={onSearchTextChangeHandler}
            onSubmit={onSearchClick}
          />
          <Divider className="divider" orientation="vertical" />
          <IconButton className="iconButton" aria-label="search" onClick={onSearchClick}>
            <SearchIcon />
          </IconButton>
        </Paper>

        {loading ? (
          <LoaderAbsoluteCentred loading={loading} />
        ) : (
          <AnimationWrapper>
            <div className="solverJobsWrapper">
              {solverJobs.map((solverJob) => {
                return <SolverJobRow key={solverJob.solverJobId} solverJob={solverJob} />;
              })}
              {(!solverJobs || !solverJobs.length) && (
                <div style={{ marginTop: 24 }}>
                  <WidgetNoResultsPlaceholder text="No Jobs" icon={HeaderIcon} flip={true} />
                </div>
              )}
            </div>
          </AnimationWrapper>
        )}

        {assetJobId && (
          <div id="createButtonWrapper" className="buttonsWrapper">
            <Button onClick={toggleCreateSolverModal} variant="outlined" fullWidth={true}>
              Create
            </Button>

            {showCreateSolverJobModal && (
              <ModalCreateSolverJob open={true} assetJobId={assetJobId} onCancelCallback={toggleCreateSolverModal} />
            )}
          </div>
        )}
      </DivWrapper>
      <div style={{ textAlign: "center" }}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Button
              onClick={() => setPageNumber(pageNumber - 1)}
              variant="outlined"
              fullWidth={true}
              {...(pageNumber === 1 ? { disabled: true } : {})}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={() => setPageNumber(pageNumber + 1)}
              variant="outlined"
              fullWidth={true}
              {...(!hasMorePages ? { disabled: true } : {})}
            >
              Next
            </Button>
          </Grid>
        </Grid>
        <LoaderAbsoluteCentred loading={loading} />
      </div>
    </WidgetSectionBase>
  );
};

function StatusFilterIcon({ checked }: { checked: boolean }) {
  return checked ? <CheckboxIconFilled fontSize="small" /> : <CheckboxIcon fontSize="small" />;
}

export function SolverJobRow({ solverJob }: { solverJob: ISolverJob }) {
  const solver = useSelector((store: RootState) => selectorGetSolverById(store, solverJob ? solverJob.solverId : ""));

  const solverimageUrl = useFileSourceSingleHook({
    fileId: solver && solver.mainImageId ? solver.mainImageId : "",
    fileType: FileTypeEnum.Image,
  });

  const currentUserId = GetUserId();
  const isAdmin = useSelector((state: RootState) => selectorGetUserPermissionIsAdmin(state, currentUserId));

  return (
    <>
      <Link
        component={RouterLink}
        to={GetSolverJobLinkBySolverJobId(solverJob.solverJobId)}
        style={{ textDecoration: "none" }}
      >
        <Grid container className="solverJobWrapper">
          <Grid item xs={3} className="solverJobImageGrid">
            <div className="solverJobImageWrapper">
              <div className="solverJobImage" style={{ backgroundImage: `url("${solverimageUrl}")` }} />
            </div>
            {isAdmin && solverJob.createdBy === currentUserId ? <div className="creatorBar"></div> : null}
          </Grid>
          <Grid item xs={9}>
            <div className="solverJobMainWrapper">
              <div>
                <Typography variant="body2" noWrap={true}>
                  {solverJob.name ? `${solverJob.name} ` : ""} - {displayFormattedDate(solverJob.created, "")}
                </Typography>
                {solverJob.persistentErrorMessage ? (
                  <Typography style={{ opacity: 0.8, fontSize: "85%", color: "rgb(200,0,0)" }} noWrap={true}>
                    {solverJob.persistentErrorMessage}
                  </Typography>
                ) : (
                  <Typography style={{ opacity: 0.8, fontSize: "85%" }} noWrap={true}>
                    {solverJob.progressLog}
                  </Typography>
                )}
                {solverJob.status === SolverJobStatusEnum.Failed ? (
                  <Chip
                    label={SolverJobStatusEnum[solverJob.status].toString()}
                    variant="outlined"
                    size="small"
                    className="fade"
                    style={{
                      transform: "scale(0.85)",
                      marginLeft: -4,
                      color: "rgb(255 255 255)",
                      backgroundColor: "rgba(226, 18, 18, 0.90)",
                      borderColor: "#ff7777",
                    }}
                  />
                ) : (
                  <Chip
                    label={AddSpacesToSentence(SolverJobStatusEnum[solverJob.status].toString())}
                    variant="outlined"
                    size="small"
                    style={{
                      transform: "scale(0.85)",
                      marginLeft: -4,
                    }}
                  />
                )}
                {solverJob.status !== SolverJobStatusEnum.Pending ? (
                  <Chip
                    label={`${solverJob.solverCount} ${solverJob.solverCount === 1 ? "solver" : "solvers"}`}
                    variant="outlined"
                    size="small"
                    style={{
                      transform: "scale(0.85)",
                      marginLeft: -4,
                    }}
                  />
                ) : null}
              </div>
            </div>
          </Grid>
        </Grid>
      </Link>
    </>
  );
}

export default SolverJobsEnquiryContainer ;
