import React from "react";
import { Switch, Route } from 'react-router-dom';
import LayoutDefault from '../components/layout/LayoutDefault';
import PageOutputListingSearch from "../components/outputType/pages/PageOutputListingSearch";

const RouteOutputs = () => {
  return (
    <Switch>
      <Route
        path={`/outputs`}
        render={(props) => (
          <LayoutDefault routeProps={props}>
            <PageOutputListingSearch {...props} />
          </LayoutDefault>
        )}
      />
    </Switch>
  );
};

export default RouteOutputs;
