import cloneDeep from "lodash/cloneDeep";
import { create } from "zustand";
import { TAssetInputFieldValue } from "../../utilities/types/AssetInputField";
import { ISolverJob, TSolverInputFieldValue } from "../../utilities/types/SolverJob";
import { TOutputTypeInputFieldValue } from "../../utilities/types/OutputType";

/**
 * Zustand state management for the 'Create Solver Job' wizard.
 * This class manages the internal state of the 'Create Solver Job' wizard.
 * All data used between wizard steps are saved and accessed via the 'useJobCreateState' utility.
 * @see https://github.com/pmndrs/zustand
 */

export enum WizardStep {
  Customise = 1,
  Review = 2,
  SolverJob = 3,
  Reports = 4,
}

export type AssetInputValuesObject = { [fieldId: string]: TAssetInputFieldValue };

export type SolverInputValuesObject = { [fieldId: string]: TSolverInputFieldValue };
export type SolverInputValuesObjectMap = { [solverId: string]: SolverInputValuesObject };

export type OutputInputValuesObject = { [fieldId: string]: TOutputTypeInputFieldValue };
export type OutputInputValuesObjectMap = { [summaryOutputTypeId: string]: OutputInputValuesObject };

type JobCreateState = JobCreateStateVals & JobCreateStateFuncs;

type JobCreateStateVals = {
  modelId: string;
  canEdit: boolean;
  step: WizardStep;
  fileId: string;
  fileName: string;

  // Set in 'Customise' step
  assetInputValuesObject: AssetInputValuesObject;
  solverInputValuesObject: SolverInputValuesObjectMap;
  appliedDecisionsOutput: { [_: string]: any };
  summaryOutputInputValuesObjectMap: OutputInputValuesObjectMap;

  // Set in 'Solver Job' step
  selectedSolverJobs: ISolverJob[];
  markedTabsSolverJobStep: { [solverId: string]: ISolverJob };

  // Set in 'Reports' step
  markedTabsReportsStep: Set<string>; // via SolverJobId

  //prerequisites
  prerequisites: {
    assetJobIds: string[];
    outputJobIds: string[];
    solverJobIds: string[];
  };
};

type JobCreateStateFuncs = {
  setPrevStep(step: WizardStep): void;
  setSolverFieldValue(solverId: string, fieldId: string, value: TSolverInputFieldValue): void;
  addSelectedSolverJob(solverJob: ISolverJob): void;
  markTabSolverJobStep(solverId: string, solverJob: ISolverJob | null): void;
  markTabReportsStep(solverId: string): void;
  setPrerequisites(key: keyof JobCreateStateVals["prerequisites"], values: string[]): void;
};

const DefaultState: JobCreateStateVals = {
  modelId: "",
  canEdit: false,
  step: WizardStep.Customise,

  fileId: "",
  fileName: "",

  assetInputValuesObject: {},
  solverInputValuesObject: {},
  appliedDecisionsOutput: {},
  summaryOutputInputValuesObjectMap: {},

  selectedSolverJobs: [],
  markedTabsSolverJobStep: {},

  markedTabsReportsStep: new Set(), // via SolverJobId
  prerequisites: {
    assetJobIds: [],
    outputJobIds: [],
    solverJobIds: [],
  },
};

const ResetState: Partial<JobCreateState> = {
  step: WizardStep.Customise,

  fileId: "",
  fileName: "",

  solverInputValuesObject: {},
  appliedDecisionsOutput: {},
  summaryOutputInputValuesObjectMap: {},

  selectedSolverJobs: [],
  markedTabsSolverJobStep: {},

  markedTabsReportsStep: new Set(), // via SolverJobId
};

/** Zustand state management for the 'Create Solver Job' wizard.
 * @see https://github.com/pmndrs/zustand
 */
const useJobCreateState = create<JobCreateState>((setRaw, get) => {
  // Wrap the set function so we always set a nice redux name
  const set = (name: string, partial: Partial<JobCreateState>) => setRaw(partial, false);
  return {
    ...DefaultState,

    setPrevStep: (targetStep) => {
      // Ensure that the user is correctly selecting a previous step
      const { step: currentStep } = get();
      if (targetStep >= currentStep) return;

      // If we're setting this to the customise set we need to reset
      if (targetStep === WizardStep.Customise) set("Reset wizard", { ...ResetState } as Partial<JobCreateState>);

      set("Set wizard step", { step: targetStep } as Partial<JobCreateState>);
    },

    setSolverFieldValue: (solverId: string, fieldId: string, value: TSolverInputFieldValue) => {
      const solverInputValuesObject = cloneDeep(get().solverInputValuesObject);
      solverInputValuesObject[solverId][fieldId] = value;
      set("Add solver input", { solverInputValuesObject } as Partial<JobCreateState>);
    },

    addSelectedSolverJob: (solverJob: ISolverJob) => {
      set("Select solver job", {
        selectedSolverJobs: [...get().selectedSolverJobs, solverJob],
      } as Partial<JobCreateState>);
    },

    /** Setting solverJob as null will reset the tab panel */
    markTabSolverJobStep: (solverId: string, solverJob: ISolverJob | null) => {
      set("Solver Job step: mark tab", {
        markedTabsSolverJobStep: { ...get().markedTabsSolverJobStep, [solverId]: solverJob as ISolverJob },
      } as Partial<JobCreateState>);
    },

    markTabReportsStep: (solverJobId: string) => {
      set("Reports step: mark tab", {
        markedTabsReportsStep: AddToSet(get().markedTabsReportsStep, solverJobId),
      } as Partial<JobCreateState>);
    },

    setPrerequisites: (key: keyof JobCreateStateVals["prerequisites"], values: string[]) => {
      set("set Prerequisites", {
        prerequisites: { ...get().prerequisites, [key]: values },
      });
    },
  } as JobCreateState;
});

function AddToSet<T>(set: Set<T>, newItem: T): Set<T> {
  const newSet = new Set(set);
  return newSet.add(newItem);
}

export function InitJobCreateState(modelId: string, canEdit: boolean) {
  useJobCreateState.setState({ ...DefaultState, modelId, canEdit });
}

/** A hook that returns whether the wizard should generate summary reports rather than task reports.
 * - Summary reports are generated when a bulk solver job is performed via uploading a file
 * - Task reports are generated hwne a single solver job is performed by simply stepping through the wizard manually.
 */
export function useJobCreateSummaryReports() {
  const fileId = useJobCreateState((s) => s.fileId);
  return !!fileId; // file ID = it's a bulk job = lots of tasks = should be summary report
}

export default useJobCreateState;
