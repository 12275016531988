import { RootState } from "..";
import { IOutputTypeInputFieldListValue } from "../../utilities/types/OutputTypeInputFieldListValue";


export function selectorGetOutputTypeInputFieldListValuesBySummaryOutputTypeId(state: RootState, summaryOutputTypeId: string): IOutputTypeInputFieldListValue[] {

    if (!state.summaryOutputTypeInputFieldListValues || !state.summaryOutputTypeInputFieldListValues.byId) return [];

    return sortOutputTypeInputFieldListValuesByOrderNumberAscending(Object.values(state.summaryOutputTypeInputFieldListValues.byId).filter(x => x.summaryOutputTypeId === summaryOutputTypeId));
}


export function selectorGetOutputTypeInputFieldListValuesByOutputTypeInputFieldId(state: RootState, outputTypeInputFieldId: string): IOutputTypeInputFieldListValue[] {

    if (!state.summaryOutputTypeInputFieldListValues || !state.summaryOutputTypeInputFieldListValues.byId) return [];

    return sortOutputTypeInputFieldListValuesByOrderNumberAscending(Object.values(state.summaryOutputTypeInputFieldListValues.byId).filter(x => x.summaryOutputTypeInputFieldId === outputTypeInputFieldId));
}


export function selectorGetOutputTypeInputFieldListValueById(state: RootState, summaryOutputTypeInputFieldListValueId: string): IOutputTypeInputFieldListValue | undefined {

    if (!state.summaryOutputTypeInputFieldListValues || !state.summaryOutputTypeInputFieldListValues.byId) return undefined;

    return state.summaryOutputTypeInputFieldListValues.byId[summaryOutputTypeInputFieldListValueId];
}


// function sortSummaryOutputTypeInputFieldListValuesByOrderNumberDescending(summaryOutputTypeInputFieldListValues: IOutputTypeInputFieldListValue[]) {
//     return summaryOutputTypeInputFieldListValues.sort((a, b) => b.orderNumber - a.orderNumber);
// }


function sortOutputTypeInputFieldListValuesByOrderNumberAscending(summaryOutputTypeInputFieldListValues: IOutputTypeInputFieldListValue[]) {
    return summaryOutputTypeInputFieldListValues.sort((a, b) => a.orderNumber - b.orderNumber);
}