import { RootState } from "..";
import { IOutputJob } from "../../utilities/types/OutputJob";

export function selectorGetOutputJobByJobId(state: RootState, solverJobId: string): IOutputJob[] {
  if (!state.summaryOutputJob || !state.summaryOutputJob.byId) return [];

  return sortOutputJobByCreatedDescending(
    Object.values(state.summaryOutputJob.byId).filter((x) => x.solverJobId === solverJobId)
  );
}

export function selectorGetOutputJobById(
  state: RootState,
  solverJobSummaryReportId: string
): IOutputJob | undefined {
  if (!state.summaryOutputJob || !state.summaryOutputJob.byId) return undefined;

  return state.summaryOutputJob.byId[solverJobSummaryReportId];
}

export function sortOutputJobByCreatedDescending(summaryOutputJob: IOutputJob[]) {
  return summaryOutputJob.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
}
