import React, { useState } from "react";
import { Divider, Typography, Button, Link, IconButton, styled } from "@mui/material";
import WidgetSectionBase from "../generic/widgets/summaries/WidgetSectionBase";
import LoaderAbsoluteCentred from "../generic/loaders/LoaderAbsoluteCentred";
import { useSelector, useDispatch } from "react-redux";
import { selectorGetFilesByEntityType } from "../../redux/file/selectors";
import { EntityTypeEnum } from "../../utilities/types/Entity";
import { RootState } from "../../redux";
import { IFile } from "../../utilities/types/File";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { IOutputType } from "../../utilities/types/OutputType";
import { createMessage } from "../../redux/message/actions";
import { MessageTypeEnum } from "../../utilities/types/Message";
import DownloadIcon from "@mui/icons-material/GetAppRounded";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { fetchDeleteFile } from "../../redux/file/actions";
import WidgetModalConfirmationDialog from "../generic/widgets/modals/WidgetModalConfirmationDialog";
import { WidgetNoResultsPlaceholder } from "../generic/widgets/WidgetNoResultsPlaceholder";
import PlaceholderIcon from "@mui/icons-material/ImportExportTwoTone";
import HeaderIcon from "@mui/icons-material/BubbleChartTwoTone";
import { GetAdminPage } from "../../routes/RouteLinkHelpers";
import { fetchImportOutputType } from "../../redux/outputType/actions";

const SectionWrapper = styled("div")(({ theme }) => ({
  height: 250,
  overflowY: "auto",
  "& .importItemWrapper": {
    padding: theme.spacing(1),
    position: "relative",
  },
}));

interface IAdminImportsSectionProps {
  canEdit: boolean;
  loading: boolean;
}

const OutputTypeImportsContainer = ({ canEdit, loading }: IAdminImportsSectionProps) => {
  const files = useSelector((store: RootState) =>
    selectorGetFilesByEntityType(store, EntityTypeEnum.SummaryOutputTypeImport)
  );

  return <AdminImportsSectionDisplay canEdit={canEdit} loading={loading} files={files} />;
};

interface IAdminImportsSectionDisplayProps {
  canEdit: boolean;
  loading: boolean;
  files: IFile[];
}

const AdminImportsSectionDisplay = ({ loading, files }: IAdminImportsSectionDisplayProps) => {
  return (
    <WidgetSectionBase
      headerText="Import files"
      subheaderText="Manage uploaded imports."
      headerIcon={<HeaderIcon />}
      fullWidthHeader={true}
    >
      <SectionWrapper>
        <div>
          {(!files || !files.length) && (
            <WidgetNoResultsPlaceholder text="No uploaded imports" icon={PlaceholderIcon} flip={true} />
          )}
          {files.map((file) => {
            return <ImportRow file={file} />;
          })}
        </div>

        <LoaderAbsoluteCentred loading={loading} />
      </SectionWrapper>
    </WidgetSectionBase>
  );
};

const ImportRow = ({ file }: { file: IFile }) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const toggleDeleteConfirmation = () => setShowDeleteConfirmation(!showDeleteConfirmation);

  async function importFile() {
    setLoading(true);

    var summaryOutputType: IOutputType = (await dispatch(
      fetchImportOutputType({ fileId: file.fileId })
    )) as any;
    if (summaryOutputType && summaryOutputType.summaryOutputTypeId) {
      dispatch(createMessage("Import successful.", MessageTypeEnum.Success));
      setTimeout(() => {
        history.push(GetAdminPage());
      }, 1000);
    } else {
      setLoading(false);
    }
  }

  async function deleteImport() {
    setLoading(true);
    await dispatch(fetchDeleteFile({ fileId: file.fileId }));
    setLoading(false);
    toggleDeleteConfirmation();
  }

  return (
    <>
      <div className="importItemWrapper">
        <Typography variant="body1" component="span">
          {file.filename}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {" "}
          - {moment.utc(file.created).fromNow()}
        </Typography>
        &nbsp;
        <Button style={{ marginLeft: 16 }} variant="outlined" onClick={importFile} size="small">
          Import
        </Button>
        <Link target="_blank" href={file.preSignedUrl} rel="noreferrer" style={{ textDecoration: "none" }}>
          <IconButton style={{ marginLeft: 16, border: "1px solid rgba(0,0,0,0.1)" }} size="small">
            <DownloadIcon />
          </IconButton>
        </Link>
        <IconButton
          style={{ marginLeft: 16, border: "1px solid rgba(0,0,0,0.1)" }}
          size="small"
          onClick={toggleDeleteConfirmation}
        >
          <DeleteIcon />
        </IconButton>
        <LoaderAbsoluteCentred loading={loading} />
      </div>
      <WidgetModalConfirmationDialog
        open={showDeleteConfirmation}
        title="Delete import file"
        subtitle="Confirm import file delete"
        description="Are you sure that you'd like to remove this import file?"
        onCancelCallback={toggleDeleteConfirmation}
        onConfirmCallback={deleteImport}
        confirmButtonText="Delete"
      />
      <Divider light={true} />
    </>
  );
};

export default OutputTypeImportsContainer;
