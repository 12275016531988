import { AppThunk } from '..';
import { Configuration } from '../../utilities/Constants';
import { GetDefaultHeaders, CheckStatus, ShowExceptionAsMessage, AddQueryStringsToUrl } from '../../utilities/ApiUtils';
import { PrepareBody, ShowError } from '../../utilities/Helpers';
import { IApiResponse } from '../../utilities/types/Api';
import { selectorGetOutputTypeMappingsBySolverId } from './selectors';
import { ACTIONS_SOLVER_OUTPUT_TYPE_MAPPING, SolverOutputTypeMappingSearchOrderTypeEnum } from './types';
import { IOutputTypeMapping } from '../../utilities/types/OutputTypeMapping';


export const receiveOutputTypeMappings = (outputTypeMappings: IOutputTypeMapping[]) => {

    var byIdObjectToDispatch: { [key: string]: IOutputTypeMapping } = {};

    for (var i = 0; i < outputTypeMappings.length; i++) {
        byIdObjectToDispatch[outputTypeMappings[i].solverSummaryOutputTypeMappingId] = outputTypeMappings[i];
    }

    return {
        type: ACTIONS_SOLVER_OUTPUT_TYPE_MAPPING.RECEIVE,
        byId: byIdObjectToDispatch,
    }
};


export const requestDeleteOutputTypeMapping = (outputTypeMappings: IOutputTypeMapping) => ({
    type: ACTIONS_SOLVER_OUTPUT_TYPE_MAPPING.DELETE,
    byId: { [outputTypeMappings.solverSummaryOutputTypeMappingId]: outputTypeMappings }
});


export interface IFetchCreateOutputTypeMappingProps {
    solverId: string,
    summaryOutputTypeIds: string[],
}


export const fetchCreateOutputTypeMapping = (request: IFetchCreateOutputTypeMappingProps): AppThunk<Promise<IOutputTypeMapping>> => async (dispatch, getStore) => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(`${Configuration.BASE_API_URL}/solverSummaryOutputTypeMappings`, {
            method: 'POST',
            headers: headers,
            body: PrepareBody(request),
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverSummaryOutputTypeMappings) {

            // Remove all previous mappings for solver to ensure we don't create zombie (client-side) state
            var existingMappings = selectorGetOutputTypeMappingsBySolverId(getStore(), request.solverId);
            for(var mapping of existingMappings){
                dispatch(requestDeleteOutputTypeMapping(mapping));
            }

            // Apply recieved ones
            dispatch(receiveOutputTypeMappings(parsedResp.data.solverSummaryOutputTypeMappings));
            return parsedResp.data.solverSummaryOutputTypeMappings;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error creating solver output type mapping.");
                return;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error creating solver output type mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}


export const fetchOutputTypeMappingsBySolverIdIfNeeded = (solverId: string): AppThunk<Promise<void>> => async (dispatch, getState) => {

    if (!selectorGetOutputTypeMappingsBySolverId(getState(), solverId).length) {
        await dispatch(fetchSearchOutputTypeMappings({ solverId, pageNumber: 1, pageSize: 100 }));
    }

    return;
}


export interface IFetchSearchutputTypeMappingsProps {
    pageNumber: number,
    pageSize: number,
    solverOutputTypeMappingId?: string,
    solverId?: string,
    orderType?: SolverOutputTypeMappingSearchOrderTypeEnum,
}


export const fetchSearchOutputTypeMappings = (searchParams: IFetchSearchutputTypeMappingsProps): AppThunk<Promise<IOutputTypeMapping[]>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, false, true);

    try {
        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverSummaryOutputTypeMappings`, searchParams), {
            method: 'GET',
            headers: headers
        });

        // NOTE: Check status handles dispatching of generic types (userdetails, files, etc)
        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverSummaryOutputTypeMappings) {
            dispatch(receiveOutputTypeMappings(parsedResp.data.solverSummaryOutputTypeMappings));
            return parsedResp.data.solverSummaryOutputTypeMappings;
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error searching solver output type mapping.");
                return [];
            }
        }
    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error searching solver output type mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return[];
    }
}


export interface IFetchDeleteOutputTypeMappingProps {
    solverSummaryOutputTypeMappingId: string,
}


export const fetchDeleteOutputTypeMapping = (props: IFetchDeleteOutputTypeMappingProps): AppThunk<Promise<IOutputTypeMapping>> => async dispatch => {

    var headers = await GetDefaultHeaders(true, true);

    try {

        var apiResponse = await fetch(AddQueryStringsToUrl(`${Configuration.BASE_API_URL}/solverSummaryOutputTypeMappings`, props), {
            method: 'DELETE',
            headers: headers
        });

        var parsedResp: IApiResponse = await CheckStatus(apiResponse);
        if (parsedResp && parsedResp.success && parsedResp.data && parsedResp.data.solverSummaryOutputTypeMappings && parsedResp.data.solverSummaryOutputTypeMappings.length) {
            dispatch(requestDeleteOutputTypeMapping(parsedResp.data.solverSummaryOutputTypeMappings[0]));
            return parsedResp.data.solverSummaryOutputTypeMappings[0];
        }
        else {
            if (!parsedResp || !parsedResp.messages || !parsedResp.messages.length) {
                ShowError("Error deleting solver output type mapping.");
                return null;
            }
        }

    }
    catch (e: unknown) {
        if (e instanceof Error) {
            ShowExceptionAsMessage(e);
            console.log("Error deleting solver output type mapping.", e.stack);
        } else {
            // Handle other types of exceptions or unknown errors.
            console.error("Unknown error:", e);
        }
        return;
    }
}