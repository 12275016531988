import { Configuration } from "../utilities/Constants";
import { Slugify } from "../utilities/Helpers";
import { EntityTypeEnum } from "../utilities/types/Entity";
import { IModel } from "../utilities/types/Model";
import { ISolver } from "../utilities/types/Solver";
import { ISolverJob } from "../utilities/types/SolverJob";
import { IOutputType } from "../utilities/types/OutputType";
import { ITask } from "../utilities/types/Task";
import { IUserDetail } from "../utilities/types/UserDetail";

// Admin
export const GetAdminPage = () => `/admin`;
export const GetAdminImportsPage = () => `/admin/imports`;
export const GetAdminUsersPage = () => `/admin/users`;
export const GetAdminFeedbackPage = () => `/admin/feedback`;
export const GetAdminIdentifiersPage = () => `/admin/identifiers`;
export const GetAdminSolversPage = () => `/admin/solvers`;
export const GetAdminOutputsPage = () => `/admin/outputs`;
export const GetAdminDiscussionsPage = () => `/admin/discussions`;
export const GetAdminUserRolesPage = () => `/admin/userRoles`;

// Jobs
export const GetSolverJobLinkBySolverJob = (solverJob: ISolverJob) =>
  `${GetSolverJobLinkBySolverJobId(solverJob.solverJobId)}`;
export const GetSolverJobLinkBySolverJobId = (solverJobId?: string) => `/solver-job/${solverJobId}`;
export const GetAssetJobLinkByAssetJobId = (assetJobId?: string) => `/asset-job/${assetJobId}`;
export const GetSolverJobCreateLinkByModelId = (modelId?: string) => `/solver-job/create/${modelId}`;

//Solvers
export const GetSolverLinkBySolver = (solver: ISolver) => `${GetSolverLinkBySolverId(solver.solverId)}`;
export const GetSolverLinkBySolverId = (solverId: string) => `/solvers/${solverId}`;
export const GetSolverURLBySolverId = (solverId: string) => `https://assetwiki.org/solvers/${solverId}`;



//Output Job
export const GetOutputJobLinkByOutput = (output: IOutputType) =>
  `${GetOutputJobLinkByOutputId(output.summaryOutputTypeId)}`;
export const GetOutputJobLinkByOutputId = (outputId: string) => `/output-job/${outputId}`;
//Outputs
export const GetSummaryOutputLinkBySummaryOutput = (summaryOutput: IOutputType) =>
  `${GetSummaryOutputLinkBySummaryOutputId(summaryOutput.summaryOutputTypeId)}`;
export const GetSummaryOutputLinkBySummaryOutputId = (summaryOutputId: string) => `/summaryOutputs/${summaryOutputId}`;
export const GetSummaryOutputURLBySummaryOutputId = (summaryOutputId: string) =>
  `https://assetwiki.org/summaryOutputs/${summaryOutputId}`;

// Models
export const GetModelsSearchPage = () => `/search`;
export const GetModelLinkByModel = (model: IModel) => `${GetModelLinkByModelId(model.modelId, model.title)}`;
export const GetModelLinkByModelId = (modelId: string, modelTitle: string) =>
  `/search/${Slugify(modelTitle.toLowerCase())}/${modelId}`;
export const GetModelURLByModelId = (modelId: string, modelTitle: string) =>
  `https://assetwiki.org/search/${Slugify(modelTitle.toLowerCase())}/${modelId}`;

export const GetModelDefinitionsLink = (modelId: string) => `${GetModelLinkByModelId(modelId, "Def")}/definitions`;
export const GetModelTemplateDownloadUrlByModelId = (modelId: string) =>
  `${Configuration.BASE_API_URL}/models/template?modelId=${modelId}`;

// Tasks
export const GetTaskLinkByTask = (task: ITask, modelTitle: string) =>
  `${GetTaskLinkByTaskIdAndModelId(task.modelId, task.taskId, modelTitle)}`;
export const GetTaskLinkByTaskIdAndModelId = (modelId: string, taskId: string, modelTitle: string) =>
  `/search/${modelTitle}/${modelId}/tasks/${taskId}`;

// Users
export const GetUserLinkByUser = (user: IUserDetail) =>
  `${GetUserLinkByUserId(user.userDetailId)}/${Slugify(user.displayName)}`;
export const GetUserLinkByUserId = (userId: string) => `/users/${userId}`;
export const GetLoginUrl = () =>
  `${Configuration.COGNITO_ENDPOINT}&redirect_uri=${Configuration.BASE_URL}&state=${encodeURIComponent(
    `&referrer=${encodeURIComponent(document.referrer || window.location.origin)}`
  )}`;
export const GetSignUpUrl = () =>
  `${Configuration.COGNITO_SIGNUP_ENDPOINT}&redirect_uri=${Configuration.BASE_URL}&state=${encodeURIComponent(
    `&referrer=${encodeURIComponent(document.referrer || window.location.origin)}`
  )}`;

// Dashboard
export const GetDashboardPage = () => `/dashboard`;

export function GetLinkForEntity(entityType: EntityTypeEnum, entityId: string) {
  if (entityType && entityId)
    switch (entityType) {
      case EntityTypeEnum.SolverJob:
        return GetSolverJobLinkBySolverJobId(entityId);
    }
}

// Utils
export function openInNewTab(url: string) {
  window.open(url, "_blank")?.focus();
}
