import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";
import LayoutDefault from '../components/layout/LayoutDefault';
import LoaderAbsoluteCentred from '../components/generic/loaders/LoaderAbsoluteCentred';
import { useFetchOutputTypesPageHook } from '../components/outputType/Hooks';
import PageOutputDetails from '../components/outputType/pages/PageOutputDetails';


const RouteSummaryOutput = () => {

    let match = useRouteMatch();

    const { fetching } = useFetchOutputTypesPageHook({
        pageNumber: 1,
        pageSize: 1,
        minPageNumberToFetch: 1,
    });

    if (fetching) return <LoaderAbsoluteCentred loading={fetching} />

    return (
        <Switch>
           <Route path={`${match.path}`} render={props => <LayoutDefault routeProps={props}><PageOutputDetails {...props} /></LayoutDefault>} />
        </Switch>
    )
}


export default RouteSummaryOutput;