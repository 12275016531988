import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { IOutputTypeMapping } from "../../utilities/types/OutputTypeMapping";
import { fetchSearchOutputTypeMappings } from "../../redux/solverOutputTypeMapping/actions";


export interface IUseFetchOutputTypeMappingsPageHookProps {
    pageNumber: number,
    pageSize: number,
    minPageNumberToFetch?: number,
    solverId?: string,
    solverOutputTypeMappingId?: string,
}


export const useFetchOutputTypeMappingsPageHook = ({ pageNumber, minPageNumberToFetch = 1, pageSize, solverOutputTypeMappingId, solverId }: IUseFetchOutputTypeMappingsPageHookProps) => {

    const dispatch = useDispatch();
    const [fetching, setFetching] = useState<boolean>(false);
    const [morePages, setMorePages] = useState<boolean>(false);
    const [lastResultSet, setLastResultSet] = useState<IOutputTypeMapping[]>([]);
    const [allResultsSet, setAllResultsSet] = useState<IOutputTypeMapping[]>([]);

    useEffect(() => {

        // This allows us to prevent initial page load fetches by setting page number to something like zero
        if (pageNumber < minPageNumberToFetch) {
            return;
        }

        (async () => {

            setFetching(true);

            try {

                // Retrieve results
                var resp: any = await dispatch(fetchSearchOutputTypeMappings({
                    pageSize,
                    pageNumber,
                    solverId,
                    solverOutputTypeMappingId,
                }));

                // Check if there are more pages to show
                if (resp && resp.success && resp.data && resp.data.solverSummaryOutputTypeMappings && resp.data.solverSummaryOutputTypeMappings.length) {
                    setMorePages(resp.data.solverSummaryOutputTypeMappings.length >= pageSize);
                    setLastResultSet(resp.data.solverSummaryOutputTypeMappings);
                }
                else {
                    setMorePages(false);
                }
            }
            finally {
                setFetching(false);
            }
        })();
    }, [pageNumber, pageSize, dispatch, minPageNumberToFetch, solverId, solverOutputTypeMappingId]);

    // Merge any new result sets with existing
    useEffect(() => {

        if (lastResultSet.some(x => !allResultsSet.some(y => y.solverSummaryOutputTypeMappingId === x.solverSummaryOutputTypeMappingId))) {
            setAllResultsSet(allResultsSet.concat(lastResultSet));
        }
    }, [lastResultSet, allResultsSet]);

    return {
        lastResultSet,
        fetching,
        morePages,
        setAllResultsSet,
        allResultsSet,
    }
}